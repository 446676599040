.content {
    box-sizing: border-box;
    padding: calc(2px* 15) 0 0;
}
.loc-label {
    position: relative;
}
.btn-back {
    display: block;
    font-size: calc(2px* 9);
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 calc(2px* 10);
    line-height: calc(2px* 10);
    background: transparent;
    margin-right: 15px;
    color: #000;
}
.btn-back i {
    vertical-align: middle;
    font-size: calc(2px* 14);
    font-weight: 300;
    margin-right: 15px;
}
.location-label {
    width: 30%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    left: auto;
    margin-right: 0;
    max-width: calc(100% - 350px);
    border: 1px solid #cccccc;
    border-radius: 8px;
}
.location-label .btn {
    width: calc(100% - 45%);
    text-align: left;
    color: #26A9E0;
    padding: 0 5px 0 10px;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.location-label .btn:before {
    content: '';
    border-right: 1px solid #000;
    position: absolute;
    right: 3px;
    height: 20px;
    top: -11px;
    transform: translateY(50%);
}
.location-label .icon-location2 {
    margin-left: 10px;
    color: #4FE765;
    z-index: 1;
}
.location-label .form-control {
    padding-right: 30px;
}
.location-label .form-control {
    border: none;
    color: #26A9E0;
}
.form-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}
.prog-grid {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.prog-grid p {
    width: 100px;
}
.prog-grid span {
    color: #4FE765;
    margin-right: 5px;
}
.row {
    display: flex;
    margin-right: calc(2px* -5);
    margin-left: calc(2px* -5);
}
.mob-block {
    display: none;
}
.tit {
    margin-bottom: calc(2px* 10);
    text-align: left;
}
.form-group {
    position: relative;
    margin-bottom: calc(2px* 10);
    display: block;
}
.nurse .form-icon {
    right: unset;
    left: 15px;
}
.nurse .form-control {
    padding: 0 15px 0 40px;
}
.nurse-checkbox li {
    box-shadow: 0 0px 10px 0 rgba(0,0,0,.1);
    border: none;
}
.card-list>li {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: calc(2px*5);
    background-color: #FFF;
    transition: .3s;
    font-size: calc(2px* 6 + 1px);
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.card-list-box {
    display: flex;
    position: relative;
}
.avtar-lg {
    width: 100px;
    height: 100px;
}
.avtar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #DFEFFF;
    border-radius: calc(2px* 4);
    position: relative;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}
.img_cover {
    width: calc(3%* 50);
    height: calc(2%* 50);
    object-fit: cover;
}
.nurse-checkbox .sa-doct-det {
    padding-right: 30px;
    width: 100%;
    padding-left: 8px;
}
.sa-doct-det {
    position: relative;
}
.nurse .status-card {
    margin: 15px 0 0px 0;
    padding: 10px;
    display: inline;
    background-color: #edf8ff;
}
.nurse [type=checkbox]:not(:checked)+label, .nurse [type=checkbox]:checked+label {
    font-weight: normal;
}
.nurse [type=checkbox]:not(:checked)+label{
        padding-left: 0;
        display: flex;
        word-break: break-word;
        cursor: pointer;
        font-size: calc(2px* 8);
}
.nurse-checkbox [type=checkbox]:not(:checked)+label:before {
    right: 0px;
    left: auto;
}
.status-card {
    background: #DFEFFF;
    align-items: center;
    border-radius: calc(2px* 3);
    font-size: calc(2px* 6 + 1px);
    flex-wrap: wrap;
}
.selected-data {
    background-color: #edf8ff;
    border: none;
    border-radius: calc(2px*5);
    overflow: hidden;
    transition: .3s;
    padding: calc(2px* 10);
    margin-bottom: calc(2px* 10);
    position: relative;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0,0,0,.1);
}
:is(.card-no-border,.card-shadow) {
    border: none;
}
.selected-data [type="checkbox"]:checked + label {
    width: 100%;
    padding-left: 0;
    padding-right: 30px;
}
[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
    position: relative;
    z-index: 1;
    color: #000;
    line-height: 18px;
}
.f-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.f--label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 20px;
}
.form-check{
    /* display: flex; */
}
.form-check-label {
    margin-left: 10px;
    margin-top: 2px;
    /* font-size: 16px;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif; */
}
.body-cnt {
    margin-left: -4px;
    width: 100%;
    max-height: calc(100vh - (10vh + 100px));
    overflow: hidden;
    overflow-y: auto;
    padding: 1px 20px;
}
.gray-light-bg {
    background-color: #F4F6FA;
}
h6, .h6 {
    font-size: calc(2px* 7);
}
.unorder-list li {
    list-style-type: disc;
    margin: 0 0 calc(2px* 5) calc(2px* 8);
    color: #000;
}
.dark-text {
    color: #000;
}
.carousel-slide .carousel.slot p:hover {
  background-color: #26a9e0;
  color: white;
  cursor: pointer;
}
.carousel-slide .carousel.slot {
  white-space: nowrap;
  overflow-x: auto;
  width: 700px;
  margin-left: 40px;
}
 
.carousel-slide .carousel.slot li {
  display: inline-block;
}
 
::-webkit-scrollbar {
  width: 12px;
}
 
::-webkit-scrollbar-track {
  background-color: transparent;
}
 
::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 6px;
}
 
.date-list-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}
 
.date-item {
  display: inline-block;
  margin-right: 10px;
}
.date-list-scroll {
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.prev {
    margin-left: 0;
}
.carousel-slide .prev {
    left: 0;
}
.carousel-slide :is(.prev, .next) {
    position: absolute;
    border: 1px solid #AF2245;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.carousel-slide .icon-left-arrow:before {
    content: "\e9d9";
}
.result .carousel-slide :is(.prev, .next), .fav-result .carousel-slide :is(.prev, .next) {
    height: 32px;
    top: 0px;
    background: #AF2245;
    cursor: pointer;
    border-radius: 4px;
    width: 34px;
    color: white;
}
.carousel-slide .next {
    right: 0;
}
.carousel-slide :is(.prev, .next) {
    position: absolute;
    border: 1px solid #AF2245;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.carousel-slide .icon-right-arrow:before {
    content: "\e9da";
}
.result-error {
    text-align: center;
    margin: auto;
    width: calc(2%* 50);
}
.w30 {
    width: 30%;
}
.result-error h4 {
    margin-top: calc(2px* 10);
    margin-bottom: calc(2px* 5);
}
.mt20 {
    margin-top: calc(2px* 10);
    border-radius: 5px;
    padding: 10px;
}
.btn-primary {
    color: #FFF;
    background-color: #AF2245;
}
.btn:disabled{
    border-color: transparent;
}
