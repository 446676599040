html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

input {
  border: none;
}

body {
  width: 144rem;
}

.payment-mxg {
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.payment-mxg .group-1261154697-tnQ {
  margin-bottom: 4.6rem;
  box-sizing: border-box;
  padding: 2.4rem 4.4rem 3.4rem 4.4rem;
  width: 100%;
  height: 8.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg .group-1261154697-tnQ .group-1261154093-zqS {
  width: 85.3rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.payment-mxg .group-1261154697-tnQ .group-1261154093-zqS .group-1261154076-Kcp {
  margin-right: 10.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154076-Kcp
  .group-1261154072-sPS {
  margin: -0.8rem 1.64rem 0.1rem 0rem;
  width: 2.7599rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154076-Kcp
  .back-xvg {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg .group-1261154697-tnQ .group-1261154093-zqS .group-1261154073-tJY {
  margin: 1.21rem 0rem 0.99rem 0rem;
  height: calc(100% - 2.2rem);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154073-tJY
  .rectangle-39545-owJ {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154073-tJY
  .rectangle-39546-Wap {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154073-tJY
  .rectangle-39547-SzG {
  margin-right: 1.0912rem;
  width: 15.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154073-tJY
  .rectangle-39548-b6U {
  width: 15.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg .auto-group-fdty-WUL {
  margin: 0rem 10rem 4rem 10rem;
  width: calc(100% - 20rem);
  height: 70rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.payment-mxg .auto-group-fdty-WUL .auto-group-xk7n-qFi {
  margin-right: 3.8rem;
  width: 60.1rem;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.payment-mxg .auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154784-xbE {
  margin-bottom: 4.8rem;
  box-sizing: border-box;
  padding: 1.5rem 1.6rem 6.3rem 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp {
  margin: 0rem 20.905rem 1.6rem 0rem;
  width: calc(100% - 20.9055rem);
  height: 7.9rem;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp
  .logo-9Qp {
  margin: 0.3rem 1.895rem 0rem 0rem;
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp
  .group-1261154716-4Gt {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp
  .group-1261154716-4Gt
  .zwaara-D9n {
  margin-bottom: 0.8rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp
  .group-1261154716-4Gt
  .laboratory-riysdh-saudi-arebia-XwA {
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .auto-group-qwxx-Qzx {
  margin-bottom: 1.6rem;
  width: 100%;
  height: 3.6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .auto-group-qwxx-Qzx
  .laboratory-m4p {
  margin-right: 19.6rem;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .auto-group-qwxx-Qzx
  .group-1261154723-uB2 {
  margin: 0.6rem 0;
  height: calc(100% - 1.2rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .auto-group-qwxx-Qzx
  .group-1261154723-uB2
  .vector-qqN {
  margin-right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .auto-group-qwxx-Qzx
  .group-1261154723-uB2
  .oct-23-2023-900-pm-nVi {
  margin-bottom: 0.1rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.9075125588;
  text-transform: capitalize;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154724-FsA
  .noha-abdullah-GQt {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154729-CJY {
  margin-right: 37.1rem;
  width: calc(100% - 37.1rem);
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154729-CJY
  .group-1261154727-8xt {
  margin-right: 1.6384rem;
  width: 1.6616rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154729-CJY
  .riyadh-saudia-arabia-UWx {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg .auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154776-kDa {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 4rem 1.6rem;
  width: 100%;
  row-gap: 1.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg {
  box-sizing: border-box;
  padding: 1.8rem 1.6rem 1.7rem 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .group-1261154735-nvx {
  margin: 0.2rem 20.219rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .group-1261154735-nvx
  .group-1261154767-vXN {
  margin: 0rem 1.6rem 0.1rem 0rem;
  width: 2.7815rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .group-1261154735-nvx
  .add-promo-code-here-rR2 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .apply-nZa {
  margin-bottom: 0.2rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur {
  width: 100%;
  height: 6.4rem;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur
  .group-1261154775-3kQ {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 1.6rem 1.7rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur
  .group-1261154775-3kQ
  .sar-0-use-wallet-balance-ye4 {
  margin: 0rem 23.1rem 0.1rem 0rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur
  .group-1261154775-3kQ
  .toggle-button-Hue {
  box-sizing: border-box;
  padding: 0rem 0.1rem 0rem 4.4rem;
  height: 100%;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 2.6rem;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur
  .group-1261154775-3kQ
  .toggle-button-Hue
  .radio-button-S1r {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN {
  width: 100%;
  height: 6.4rem;
  position: relative;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN
  .group-1261154768-4Z2 {
  width: 54.4852rem;
  height: 2.7rem;
  position: absolute;
  left: 1.6814rem;
  top: 1.8rem;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN
  .group-1261154771-miL {
  box-sizing: border-box;
  padding: 1.8rem 1.65rem 1.9rem 1.7rem;
  width: 56.9rem;
  height: 6.4rem;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN
  .group-1261154771-miL
  .pay-with-qitaf-point-smN {
  margin-right: 34.75rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN
  .group-1261154771-miL
  .vector-QFW {
  margin-top: 0.1rem;
  width: 0.9rem;
  height: 1.8rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154772-Jbn {
  box-sizing: border-box;
  padding: 1.8rem 1.65rem 1.9rem 1.7rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154772-Jbn
  .pay-with-qitaf-point-ST6 {
  margin-right: 34.75rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154772-Jbn
  .vector-Znc {
  margin-top: 0.1rem;
  width: 0.9rem;
  height: 1.8rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg .auto-group-fdty-WUL .group-1261154781-8z8 {
  width: 60.1rem;
  height: 100%;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .select-payment-method-tTW {
  margin: 0rem 0rem 1.6rem 0.2rem;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}

.payment-mxg .auto-group-fdty-WUL .group-1261154781-8z8 .auto-group-anua-c8c {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem 1.6rem 1.8rem;
  width: 100%;
  height: 6.4rem;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem 0.6rem 0 0;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .group-1261154734-ApL {
  margin-right: 19.1rem;
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .group-1261154734-ApL
  .radio-button-iax {
  margin-right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .group-1261154734-ApL
  .split-into-3-payments-ejW {
  margin-top: 0.1rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .image-79-n52 {
  width: 7.3998rem;
  height: 2.4rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6 {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem;
  width: 100%;
  height: 6.4rem;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA
  .group-1261154735-xdi {
  margin-right: 20.7rem;
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA
  .group-1261154735-xdi
  .radio-button-Wv8 {
  margin-right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA
  .group-1261154735-xdi
  .interests-free-payment-Fcp {
  margin-top: 0.1rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA
  .image-80-NxL {
  width: 6.2rem;
  /* height: 2.4rem; */
  position: relative;
  background-size: 112.576% 200%;
  background-position: -0.4004rem -1.2rem;
  /* background-image: url('../assets/image-80.png'); */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem 1.633rem 1.6rem 1.6rem;
  width: 100%;
  height: 6.4rem;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ
  .group-1261154735-iep {
  margin-right: 22.1rem;
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ
  .group-1261154735-iep
  .radio-button-sXi {
  margin-right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ
  .group-1261154735-iep
  .apple-pay-1tp {
  margin-top: 0.1rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ
  .image-83-Z9e {
  width: 5.4667rem;
  /* height: 2.4rem; */
  position: relative;
  background-size: 184.877% 133.333%;
  background-position: -1.4681rem -0.4rem;
  /* background-image: url('../assets/image-83.png'); */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8 {
  margin-bottom: 3.2rem;
  box-sizing: border-box;
  padding: 1.6rem 1.633rem 1.6rem 1.6rem;
  width: 100%;
  height: 6.4rem;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .group-1261154735-VhW {
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .group-1261154735-VhW
  .radio-button-Fgg {
  margin-right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .group-1261154735-VhW
  .card-payment-Cbv {
  margin-top: 0.1rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z {
  box-sizing: border-box;
  padding: 0.4rem 0rem 0.4rem 10.6rem;
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-81-fVW {
  margin-right: 1.6rem;
  width: 7.186rem;
  height: 2.4rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-84-zng {
  margin-right: 1.6rem;
  width: 7.4113rem;
  height: 2.4rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-82-YZJ {
  margin-right: 1.6001rem;
  width: 2.4048rem;
  height: 2.4rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-85-6Kv {
  margin-right: 1.6rem;
  width: 3.065rem;
  height: 2.4rem;
  position: relative;
  background-size: 163.132% 148.759%;
  background-position: -0.98rem -0.6501rem;
  /* background-image: url('../assets/image-85-u8g.png'); */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-86-Cdr {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .price-breakup-KCg {
  margin: 0rem 0rem 1.6rem 1.6rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .selected-package-F6L {
  margin-left: 1.6rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154763-WHA {
  margin-bottom: 2.2rem;
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154763-WHA
  .health-check-packages-home-visit-fee-vat--FkY {
  margin: 0rem 31.5rem 0.2rem 0rem;
  max-width: 17.7rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #999999;
  font-family: Poppins, "Source Sans Pro";
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154763-WHA
  .health-check-packages-home-visit-fee-vat--FkY
  .health-check-packages-home-visit-fee-vat--FkY-sub-0 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  font-family: Poppins, "Source Sans Pro";
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154763-WHA
  .health-check-packages-home-visit-fee-vat--FkY
  .health-check-packages-home-visit-fee-vat--FkY-sub-1 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ec3434;
  font-family: Poppins, "Source Sans Pro";
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154763-WHA
  .sar-350-sar-100-sar-673-Z2U {
  max-width: 7.7rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154765-sYx {
  box-sizing: border-box;
  padding: 0.9rem 0.9rem 0rem 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154765-sYx
  .amount-payable-R4g {
  margin-right: 32.7rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154765-sYx
  .sar-5175-kck {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg .group-1261154783-zX6 {
  box-sizing: border-box;
  padding: 0.8rem 10rem 0.9rem 10rem;
  width: 100%;
  height: 4.4rem;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  flex-shrink: 0;
}

.payment-mxg .group-1261154783-zX6 .group-1261154782-ue4 {
  margin-right: 69.4rem;
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg .group-1261154783-zX6 .group-1261154782-ue4 .group-1261154778-Tfa {
  margin: 0.2rem 1.2rem 0.1rem 0rem;
  width: 2.4rem;
  height: calc(100% - 0.3rem);
  position: relative;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154783-zX6
  .group-1261154782-ue4
  .group-1261154778-Tfa
  .c-ydv {
  width: 1.2rem;
  height: 2.3rem;
  position: absolute;
  left: 0.6rem;
  top: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}

.payment-mxg
  .group-1261154783-zX6
  .group-1261154782-ue4
  .group-1261154778-Tfa
  .ellipse-89-tVz {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 0;
  top: 0;
  border: solid 0.1rem #ffffff;
  box-sizing: border-box;
  border-radius: 1.2rem;
}

.payment-mxg .group-1261154783-zX6 .group-1261154782-ue4 .zwaara-llc-1qW {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg .group-1261154783-zX6 .group-1261154780-kYC {
  height: 100%;
  display: flex;
  /* align-items: center; */
  flex-shrink: 0;
}

.payment-mxg .group-1261154783-zX6 .group-1261154780-kYC .privacy-policy-6rx {
  margin-right: 3.8rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-mxg
  .group-1261154783-zX6
  .group-1261154780-kYC
  .terms-and-conditions-FE4 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* .slider::after {
  background-color: #af2245;
} */

input:checked + .slider {
  background-color: #f7e9ec;
}

input:focus + .slider {
  box-shadow: 0 0 1px blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #af2245;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.form-check-input {
  margin-right: 5px;
}
.radio_btn_container {
  position: relative;
  cursor: pointer;
  margin: 1rem -12.8rem 1.6rem 0rem;
  width: calc(100% - 27.2rem);
  display: flex;
  align-items: center;
}
