.our-featured-tests-pop-up-hEc {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding: 5px 45px;
}
.our-featured-tests-pop-up-hEc .health-check-packages-VnC {
  color: #002a56;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154877-nWx .group-1171275053-Wxk .group-1171275049-1ec {
  width: 32px;
  height: 32px;
  text-align: center;
  font-weight: 600;
  line-height: 1.2259999381;
  color: #111535;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.2rem #888b9e;
  box-sizing: border-box;
  background-color: rgba(27, 43, 58, 0.1000000015);
  border-radius: 100%;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154877-nWx .group-1171275053-Wxk {
  margin: 0.8rem 63.7rem 0.8rem 0rem;
  height: calc(100% - 1.6rem);
  display: flex;
  column-gap: 1.3rem;
  align-items: center;
  flex-shrink: 0;
}

.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154877-nWx .group-1171275053-Wxk .group-371-Pv4 {
  width: 32px;
  height: 32px;
  position: relative;
  border: solid 0.2rem #af2245;
  box-sizing: border-box;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: #af2245;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6  .group-1261154877-nWx .group-1171275053-Wxk .group-371-Pv4 .ellipse-57-9eL {
  width: 28px;
  height: 28px;
  border: solid 2px #ffffff;
  border-radius: 100%;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6  .group-1261154877-nWx .group-1171275053-Wxk .group-371-Pv4 .item--sqE {
  text-align: center;
  font-weight: 600;
  line-height: 1.2259999381;
  color: #ffffff;
  font-family: Open Sans, 'Source Sans Pro';
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6  .group-1261154877-nWx .group-1171275053-Wxk .item-1-fz4 {
  margin-bottom: 0px;
  text-align: center;
  font-weight: 600;
  line-height: 1.2228587189;
  color: #111535;
  font-family: Open Sans, 'Source Sans Pro';
  white-space: nowrap;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154877-nWx .group-1261154603-x5z {
  padding: 10px 20px;
  cursor: pointer;
  line-height: 1.5;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .zw_select_btn{
  display: flex;
     justify-content: right;
    align-items: center;
}

.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154551-xJQ .group-1261154604-oZv li{
  list-style-type: disc;
  color: #000000;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154551-xJQ {
  padding: 50px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154551-xJQ .group-1261154604-oZv {
  padding: 50px;
  background-color: #ffffff;
  border-radius: 6px;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154591-xbE .group-1261154590-3Mn {
  width: 100%;
  row-gap: 1.1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154591-xbE .group-1261154590-3Mn ul {
  width: 100%;
  padding-left: 0;
  border-radius: 1rem;
  flex-shrink: 0;
}
.our-featured-tests-pop-up-hEc .group-1261154596-dV6 .group-1261154601-MAC .group-1261154592-pxt .group-1261154591-xbE .group-1261154590-3Mn .group-1261154588-MdN li{
  box-sizing: border-box;
  padding: 15px 75px;
  color: #111535;
  border: solid 0.1rem #af2245;
  box-shadow: 0 2.8rem 2.5rem rgba(0, 0, 0, 0.0399999991);
  background-color: #ffffff;
  border-radius: 1rem;
  margin-bottom: 10px;
}
.zw_popup  .zw_report_name{
  vertical-align: middle;
}

