.join-counts {
    background-image: linear-gradient(249.24deg, rgba(38, 169, 224, 0.15) 34.27%, rgba(79, 231, 101, 0.15) 89.68%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
}
.top120 {
    margin-top: 120px;
}
.join-counts li {
    width: calc(100%/3 - (1 - 1/3)*10px);
    text-align: center;
    border-right: 1px solid #474554;
}
.primary-text {
    color: #26A9E0;
}
.join-counts li span {
    color: #474554;
}
.join-counts li:last-child {
    border: none;
}
.join-counts li {
    width: calc(100%/3 - (1 - 1/3)*10px);
    text-align: center;
}
.h2 {
    font-weight: 600;
}
@media only screen and (max-width: 768px),
only screen and (max-device-width: 768px){
    .mob-none{
        display: none;
    }
}
@media only screen and (max-width: 1366px),
only screen and (max-device-width: 1366px) {
    .col-md6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    /* mob-none{
        display: none;
    } */
}