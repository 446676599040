.zw_popup .card {
    border: none;
}

.zw_popup .m-0 {
    margin: 0px;
}

.zw_popup .zw_form_group {
    margin-bottom: 15px;
}

.zw_popup .zw_form_control {
    border: 2px solid #AF2245;
    border-radius: 6px;
}

.zw_popup .form-control {
    height: calc(2px* 25);
}

.zw_popup .form-icon {
    top: 10px;
}

.zw_popup .zw_select_country {
    background-color: #f7e9ec;
}

.zw_popup .zw_border_none {
    border: none;
}

.zw_popup .zw_border_radius {
    border-radius: 6px 0 0 6px;
}

.zw_popup .zw_sbmtbtn_radius {
    border-radius: 6px;
}

.zw_popup .prog-bar-per {
    background-color: #AF2245;
}

.zw_popup .sps-dialog-close i {
    color: #777777;
    border: 2px solid #777777;
}

.zw_popup .zw_radio_btn_label {
    vertical-align: middle;
    margin-right: 30px;
}

.zw_popup .zw_radio_group {
    margin-left: 20px;
}

.zw_popup .zw_radio_btn {
    border: 1px solid #AF2245;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.zw_popup .zw_date i {
    padding-right: 10px;
}

.zw_popup #SignInPopUpModal3 .modal-dialog-centered {
    max-width: 70%;
    margin: auto;
}

.zw_popup #SignInPopUpModal3 .signin-dialog {
    padding: 50px 10px;
}

.zw_popup #SignInPopUpModal3 .sps-dialog-close i {
    color: #FF2626;
    border: 2px solid #FF2626;
}

.zw_popup #SignInPopUpModal3 .zw_succ_p {
    padding: 20px 85px;
}


.zw_popup .zw_icon_drop {
    top: 18px;
}

.zw_popup .verifi-code {
    margin: 40px;
}

.zw_popup .icon-reload::before {
    content: url('../../public/images/captcha_reload.svg') !important;
    cursor: pointer;
}

.zw_popup .zw_country_sec {
    width: 100px;
    position: absolute;
    height: 45px;
    margin-left: 2px;
    margin-top: 3px;
}

.zw_popup .zw_country_flag {
    position: relative;
}

.zw_popup .cuntry-plholder {
    position: relative;
    top: 10px;
    left: 25px;
    opacity: 1;
}

.signup-cuntry-plholder{
    position: relative;
    top: 13px;
    left: 40px;
    opacity: 1;
}

.zw_popup select:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.zw_popup .zw_lang_toggle {
    display: flex;
    justify-content: space-between;
}

.zw_popup .zw_btn_primary {
    border: 1px solid #f7e9ec;
    color: #AF2245;
    padding: 10px 35px;
    width: 40%;
    background-color: #f7e9ec;
}

.zw_popup .zw_btn_primary:hover {
    color: #AF2245;
    background-color: #f7e9ec;
    border: 1px solid #AF2245
}

.zw_popup .zw_btn_primary.select,
.zw_popup .zw_btn_primary:active {
    background: white !important;
    border-radius: 6px;
    color: #AF2245 !important;

}

.zw_popup .zw_btn_primary:active {
    border: 1px solid #AF2245 !important;
}

.zw_popup .zw_Healthpackage .modal-dialog {
    max-width: 80%;
    margin: 20px auto;
}