.z-service-bg {
    position: relative;
}

.pos-rel {
    position: relative;
}

.z-services-location {
    position: absolute;
    background: linear-gradient(45deg, rgba(38, 169, 224, 0.45) 0%, rgba(79, 231, 101, 0.45) 100%);
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    z-index: 1;
    top: 300px;
    right: 100px;
    cursor: pointer;
}

.z-services-location .fa-location {
    color: white;
    vertical-align: middle;
}

.z-mob-block {
    display: none;
}

.h1 {
    font-size: 54px;
}

.sa-pos-rel{
    /* margin-left: 100px; */
}

.sa-service-bg {
    position: relative
}

.sa-service-bg::before {
    content: '';
    position: absolute;
    height: 600px;
    background: url(https://sanar-assets.com/images/static/v5/home/banner-lines.png) 100% no-repeat var(--sa-primary);
    clip-path: polygon(100% 0%, 100% 74.8%, 0% 100%, 0% 100%, 0% 0%);
    left: 0;
    right: 0
}

.sa-services h1 {
    color: #AF2245;
    font-size: 46px;
    /* font-weight: 200;
    text-align: center;
    padding: 80px 120px 0;
    font-weight: 700;
    color: #FFF; */
}
.zwaara_h2{
    font-size: 16px;
    color: #AF2245;
    padding-top: 20px;
}
.zwaara_sr{
    font-size: 24px;
    color: #111535;
}
.z-banner-desc {
    color: #FFF;
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    margin-top: 700px;
    padding-top: 35px;
}

.sa-link {
    color: #26a9e0;
    text-decoration: underline;
    cursor: var(--sa-pointer);
}

.z-services-list li img {
    height: 100px;
    margin: 0 15px 0 0
}

.z-services-list li img:hover {
    color: #fff;
}

.sa-health-ins {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
    color: #626262;
    padding: 0px 35px 0px 81px;
}

.sa-health-ins img {
    vertical-align: middle;
    margin-right: 10px;
    margin-top: 10px;
}

.sa-services-list li:hover {
    background: linear-gradient(270deg, #E2F8EA 6.35%, #DEF2FA 65.71%, #DEF2FA 93.23%)
}




.wb-title {
    color: #002A56;
    font-weight: 600;
    font-size: xxx-large;
}

.wb-card-title {
    color: #002A56;
    font-weight: 600;
    font-size: 20px;
    padding-right: 25px;
}

.wb-card-text {
    color: black;
    font-size: 13px;
}

.bs-card {
    padding-bottom: 50px;
    padding-top: 50px;
    width: 310px;
    padding-left: 57px;
}

.wb-card {
    position: static;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 100px;
    height: 400px;
    width: 285px;
    top: 50px;
    left: 23px;
}

.wb-treatment-card {
    width: 100%;
    padding: 30px;

}

.wb-treatment-card i {
    font-size: 40px;
    margin: 10px 0;
    display: block;
    margin-left: -30px;
}

.wb-sprite-new {
    content: '';
    background: url(https://sanar-assets.com/images/sprite/v5/sprite.svg) no-repeat;
    background-size: 1600px;
}



.wb-journey {
    background-position: 2px 0 !important;
    width: 50px;
    height: 35px;
}

.ci-ele-cardio {
    background-position: -46px 0 !important;
    width: 44px;
    height: 35px;
}

.ci-doc-bag {
    background-position: -90px 0 !important;
    width: 47px;
    height: 43px;
}

.ci-money-fill {
    background-position: -136px 0 !important;
    width: 45px;
    height: 41px;
}


.b-card:hover {
    transform: scale(1);
    box-shadow: 0 10px 20px #002A56, 0 4px 4px rgba(0, 0, 0, .06);
    background-color: #26a9e0;
    color: white;
    text-transform: uppercase;
    height: auto;
    border-radius: 20px;
}

.b-card:hover h5 {
    color: white;
}

.b-card:hover p {
    color: white;
}



.ci-odr-top120 {
    margin-top: 80px;
    margin-bottom: 70px;
}

.ci-odr-order-sanar h2 {
    text-align: center;
    margin-bottom: 90px;
}

.ci-odr-order-sanar ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
}

.ci-odr-order-sanar ul li {
    width: calc(100%/4 - (1 - 1/4)* 20px);
    padding: 0 38px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
}

.ci-odr-common-title {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.ci-odr-order-sanar ul li i {
    font-size: 50px;
    color: #020288;
    position: relative;
}

.ci-odr-icon-bg {
    font-size: 30px;
    width: 50px;
    height: 48px;
    position: relative;
    color: #020288;

    content: '';
    right: 0;
    left: 8px;
    top: 0;
    background: url(https://sanar-assets.com/images/static/v5/icon-bg.svg) no-repeat;
    background-size: 100%;
}

.ci-odr-order-sanar ul li i small {
    position: absolute;
    top: -14px;
    left: -25px;
    font-size: 14px;
    background-color: #26a9e0;
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 23px;
    border-radius: 50%;
}

.ci-odr-order-sanar ul li h3 {
    margin: 15px 0;
    font-size: 22px;
}

.ci-odr-header-body {
    color: #002a56;
    font-weight: 600;
}

.ci-odr-order-sanar ul li span {
    color: #aca9bb;
    font-weight: 300;
}

.ci-odr-order-sanar ul li i.icon-arrow-right-thick {
    position: absolute;
    top: 9px;
    right: -10px;
    font-size: 15px;
    color: #26a9e0;
}




.bi-dr-top120 {
    margin-top: 120px;
}

.bi-dr-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bi-dr-title {
    padding-right: 20px;
    font-size: 44px;
}

.bi-dr-aviailable {
    background-color: #eefdf0;
    align-items: center;
    padding: 10px 15px;
    border-radius: 15px;
    display: -webkit-flex;
    margin-right: 35px;
}

.bi-dr-section span {
    background-color: #4fe765;
    width: 45px;
    height: 45px;
    display: block;
    border-radius: 50%;
    border: 10px solid #cef8d4;
}

.bi-dr-avi-descr {
    color: #4fe765;
    width: 220px;
    margin-left: 10px;
    font-weight: 500;
}

.bi-dr-list-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px 0;
    padding-top: 35px;
}

.bi-dr-list-section li {
    color: black;
    position: relative;
    padding-left: 45px;
    font-weight: 300;
    width: 25%;
}

.bi-dr-list-section.bi-tick li i {
    top: -5px;
}

.bi-tick li i {
    color: #fff;
    background-color: #4fe765;
    border-radius: 50px;
    font-size: 9px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bi-tick li.bi-dr-morelist {
    padding-left: 0;
    color: #0800ff;
    cursor: pointer;
}

.bi-dr-list-section li {
    font-weight: 300;
    width: 25%;
    position: relative
}



.bi-dr-cursor {
    cursor: pointer;
}

.bi-dr-mb0 {
    margin-bottom: 0;
}

.bi-dr-doc-slider {
    text-align: center;
    width: 100%;
}

.bi-dr-card-lg {
    padding: 30px;
    height: 350px;
    width: 280px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.bi-dr-card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
    border-radius: 20px;
}

.bi-dr-doc-slider img {
    margin: 0 auto;
    object-fit: cover;
}

.bi-dr-avtar-lg {
    width: 100px;
    height: 100px;
}

.bi-dr-avtar-circle {
    border-radius: 50%;
}

.bi-dr-avtar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    position: relative;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}

.bi-dr-doc-slider h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    margin: 30px 0 15px;
    font-size: 22px;
}

.bi-dr-header-body {
    color: #002A56;
}

.bi-dr-header-body p {
    color: #474554;
    font-weight: 300;
}

p.bi-dr-gray-text {
    color: #cccccc;
    font-weight: 300;
}

.bi-dr-mb30 {
    margin-bottom: 30px;
}

.bi-dr-doc-slider span {
    border: 1px solid #cccccc;
    padding: 2px 17px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    margin: 0 auto;
    font-weight: 500;
}

.bi-dr-doc-slider span i {
    color: #ffc14f;
    margin-right: 10px;
    font-size: 22px;
    line-height: 26px;
}

.icon-star {
    cursor: pointer;
}

.bi-dr-hl-last-chaild {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 100px 0;
    flex-direction: column;
    margin-bottom: 0;
}

.bi-dr-hl-last-chaild a {
    padding: 8px 20px;
    background-color: #26a9e0;
    color: #fff;
    border-radius: 10px;
    text-decoration: none;
    outline: none;
}

.bi-dr-lise-doc .bi-dr-prev {
    width: 56px;
    height: 56px;
    font-size: 20px;
    color: #26a9e0;
    background: #fff;
    border: none;
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 50px;
}

.bi-dr-carousel-slide .bi-dr-prev {
    left: 0;
}

.bi-dr-lise-doc .bi-dr-next {
    width: 56px;
    height: 56px;
    font-size: 20px;
    color: #26a9e0;
    background: #fff;
    border: none;
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 50px;
    margin-left: 20px;
}

.bi-dr-carousel-slide .bi-dr-next {
    right: 0;
}

.bi-dr-lise-doc .bi-dr-helth-btn {
    text-align: right;
    margin-top: -43px;
}

.bi-dr-lise-doc .bi-dr-helth-btn button {
    position: relative;
    z-index: 11;
    outline: none;
    border: none;
}

.bi-dr-btn-lg {
    padding: 0 25px;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    border-radius: 10px;
}

.bi-dr-btn-primary {
    color: #fff;
    background-color: #26a9e0;
    font-family: Avenir;
}






.bi-hlt-top120 {
    margin-top: 120px;
}

.bi-services-hlt {
    margin-bottom: 120px;
}

.bi-hlt-gutter20 {
    margin-right: calc(var(--sa-pixel)* -10);
    margin-left: calc(var(--sa-pixel)* -10);
}

.bi-hlt-col6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.bi-hlt-serv-in {
    text-align: center;
    padding: 70px 50px 40px;
    width: 600px;
    height: 350px;
    background: url(https://sanar-assets.com/images/static/new-theme/our-services-bg.svg) no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.bi-hlt-card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
}

.bi-hlt-serv-in h3 {
    color: #fff;
    font-size: 35px;
    padding-top: 15px;
}

.bi-hlt-serv-in p {
    color: #fff;
    width: 80%;
    margin: 0 auto 40px;
    font-weight: 300;
}

.bi-hlt-request-bg {
    background: url(https://sanar-assets.com/images/static/new-theme/request-now-bg.svg) no-repeat;
    background-size: cover;
}

.bi-hlt-serv-in button {
    background: #fff;
    color: #26A9E0;
    border: none;
}

.bi-hlt-btn-lg {
    padding: 0 25px;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    border-radius: 10px;

}

.bi-hlt-serv-in button i {
    vertical-align: middle;
}

.bi-hlt-pr10 {
    padding-right: 10px;
}

.bi-hlt-btn:hover {
    color: blue;
}

.bi-hlt-btn-hov:hover{
    background-color: #002a56;
    color: white;
}

/* ------------------------ */

* {
    box-sizing: border-box;
}

.bi-dr-Slider {
    --slide-width: 25%;
    --slide-min-width: 15rem;

    position: relative;
    overflow: hidden;
    margin-right: 20px;
}

.bi-dr-Slider__track {

    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-inline: 1rem;
    scroll-behavior: smooth;

    list-style-type: none;
    padding: 0;
    margin-right: calc(1rem * -1);
}


.bi-dr-Slider ::-webkit-scrollbar {
    display: none;
}


.bi-dr-Slider .buttons {
    margin-top: 1rem;

    [disabled] {
        opacity: .5;
    }
}

.bi-dr-Slider__buttons {
    margin-top: 1rem;
}

.bi-dr-Slider__buttons [disabled] {
    opacity: .5;
}

.bi-dr-Slider__button {
    border: 1px solid #ccc;
}


/*----------------------------------------*/

.prf-top-space{
    margin-top: 115px;
}

.prf-card-shadow.prf-service-prov {
    border-radius: 0;
    padding: 50px;
}

.prf-service-prov {
    width: 70%;
    margin: 40px auto;
}

.prf-card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
}

.prf-btn-back {
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 20px;
    line-height: 20px;
    vertical-align: middle;
    background: transparent;
    margin-right: 15px;
    color: #000;
}

.prf-btn-back i {
    vertical-align: middle;
    font-size: 28px;
    font-weight: 300;
    margin-right: 15px;
}

.prf-mb50 {
    margin-bottom: 50px;
}

.prf-text-center {
    text-align: center;
}

.prf-title {
    font-size: 35px;
    margin: 0;
    color: #002a56;
    font-weight: 700;
}

.prf-subtitle {
    margin: 15px 0;
}

.prf-gutter5 {
    padding-right: calc(var(--sa-pixel)*2 + 1px);
    padding-left: calc(var(--sa-pixel)*2 + 1px);
}

.prf-row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.prf-col6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 6px;
    padding-right: 6px;
}

.prf-form-group {
    position: relative;
    margin-bottom: 20px;
    display: block;
}

.prf-form-group label,
label {
    color: #000;
    display: block;
    line-height: normal;
    margin-bottom: 5px;
    font-size: calc(var(--sa-pixel)* 6 + 1px);
    font-weight: normal;
}

.prf-service-prov .prf-form-control {
    border-color: #E6E5EB;
}

.prf-form-control {
    background: #fff;
    height: 40px;
    line-height: 40px;
    color: #000;
    border: 1px solid #aca9bb;
    padding: 0 35px 0 15px;
    width: 100%;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    white-space: normal;
    text-overflow: ellipsis;
}


.prf-gutter10 {
    margin-right: calc(var(--sa-pixel)* -5);
    margin-left: calc(var(--sa-pixel)* -5);
}

.prf-col3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 6px;
    padding-right: 6px;
}

.prf-form-img {
    width: 30px;
    right: auto;
    left: 10px;
    top: 5px;
}

.prf-form-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}

.prf-form-country-icon {
    position: absolute;
    right: 15px;
    top: 5px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}

.prf-inp-flag {
    padding-left: 50px;
    cursor: pointer;
}

.prf-ccode .prf-custom-dropd{
    top: 50px;
}

.prf-custom-dropd{
    position: absolute;
    left: 0;
    right: 0;
    min-width: 325px;
    padding: 15px;
    box-shadow: 0 0 10px #ddd;
    border-radius: 10px;
    z-index: 99;
    background: #FFF;
    max-height: 200px;
    min-height: 100px;
    overflow: hidden;
    overflow-y: auto;
}

#comment {
    width: 800px;
    height: 130px;
}

.prf-col9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 10px;
    padding-right: 10px;
}

.prf-pag-btn {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.prf-cursor {
    cursor: pointer;
}

.prf-mr10 {
    margin-right: calc(var(--sa-pixel)* 5);
}

.prf-primary-text {
    color: #26a9e0;
    font-weight: 600;
}

.prf-btn-primary {
    color: #fff;
    background-color: #26a9e0;
    outline: none;
    text-align: center;
    font-size: 21px;
    border-radius: 5px;
    width: 45px;
    margin-left: 11px;
    height: 40px;
    border: var(--sa-border-solid) var(--sa-transparent);
    padding: 0 15px;
    line-height: 40px;
    display: inline-block;
    font-weight: 400;
}

.prf-arrow {
    font-size: 15px;
}

