.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.picker-group {
  display: flex;
}

.vue-scroll-picker {
  border: 1px solid #ccc;
  margin: 10px;
  overflow-y: scroll;
  height: 200px;
  width: 80px;
  position: relative;
}

.vue-scroll-picker-rotator {
  position: absolute;
}

.vue-scroll-picker-item {
  text-align: center;
  line-height: 28px;
  /* Assuming each item's height is 40px */
}

.selected-date {
  margin-top: 20px;
}