.sa-container {
  width: 1160px;
  margin: 0 auto;
  max-width: 1160px;
  margin-top: 150px;
}

.sa-content {
  box-sizing: border-box;
  padding: 2px * 15 0 0;
}

.sa-btn-back {
  display: inline-block;
  font-size: 2px * 9;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0 0 2px * 10;
  line-height: 2px * 10;
  vertical-align: middle;
  background: transparent;
  margin-right: 15px;
  color: #000;
}

.sa-btn-back i {
  vertical-align: middle;
  font-size: 2px * 14;
  font-weight: 300;
  margin-right: 15px;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.icon-arrow-left:before {
  content: "\e9d9";
  font-size: 30px;
}
.back-text {
  font-size: large;
}
.sa-prog-grid {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.sa-prog-grid p {
  width: 100px;
}

p {
  margin: 2px * 2 + 1px 0;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.sa-prog-grid span {
  color: #4fe765;
  margin-right: 5px;
}

* {
  box-sizing: border-box;
}
.sa-prog-bar {
  width: calc(100% - 100px);
  background-color: #dde0e6;
  height: 4px;
  border-radius: 10px;
  position: relative;
}

.sa-prog-bar-per {
  width: 50%;
  background-image: linear-gradient(to right, #4fe765, #26a9e0);
  height: 4px;
  border-radius: 10px;
}

.sa-d-none,
.sa-dialog .sa-dialog-back-btn,
.sa-mob-block,
.sa-nav-mob,
.sa-doc-det-block,
.sa-mob-flex .sa-edit-pro i {
  display: none;
}
h5,
.h5 {
  font-size: 2px * 8;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}
.sa-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 2px * -5;
  margin-left: 2px * -5;
}

@media only screen and (max-width: 1366px),
  only screen and (max-device-width: 1366px) {
  .sa-col-md8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}
[class*="col"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 1px;
  width: 2px * 50;
  padding-right: 2px * 5;
  padding-left: 2px * 5;
  position: relative;
}

.sa-info-light-bg {
  background-color: #f8fbff;
  border-radius: 10px;
}
:is(.sa-card-no-border, .sa-card-shadow) {
  border: none;
}
:is(.sa-card, .sa-card-shadow, .sa-card-no-border) {
  border-radius: 2px * 5;
  overflow: hidden;
  color: var(--sa-ebony);
  transition: 0.3s;
  padding: 2px * 10;
  margin-bottom: 2px * 10;
  position: relative;
}
.sa-caregiver-in {
  padding-right: 110px;
  position: relative;
  margin-top: 20px;
}
h5,
.h5 {
  font-size: 2px * 8;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}
:is(.sa-caregiver-work p, .sa-cargiver-task p, .sa-caregiver-in p) {
  color: 9b9b9b;
}

p {
  margin: 2px * 2 + 1px 0;
}

.sa-mb10,
.sa-smthng-wrg h5 {
  margin-bottom: 2px * 5;
}
.sa-mt10 {
  margin-top: 2px * 5;
}
.sa-primary-text {
  color: #26a9e0;
}
h6,
.h6 {
  font-size: 2px * 7;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  font-weight: bold;
  font-weight: 600;
}
@media only screen and (max-width: 1366px),
  only screen and (max-device-width: 1366px) {
  .sa-col-md4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
[class*="col"] {
  box-sizing: border-box;
  min-height: 1px;
  width: 2% * 50;
  padding-right: 2px * 5;
  padding-left: 2px * 5;
  position: relative;
}

.sa-fw500 {
  font-weight: 500;
}
.sa-mb20 {
  margin-bottom: 2px * 10;
}
h5,
.h5 {
  font-size: 2px * 8;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
user agent stylesheet h5 {
  display: block;

  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
