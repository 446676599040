.sa-container {
  width: 1160px;
  margin: 0 auto;
  max-width: 1160px;
}
.sa-content {
  box-sizing: border-box;
  padding: 2px * 15 0 0;
}
div {
  display: block;
}

.sa-btn-back {
  display: inline-block;
  font-size: 2px * 9;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0 0 2px * 10;
  line-height: 2px * 10;
  vertical-align: middle;
  background: transparent;
  margin-right: 15px;
  color: #000;
}
.sa-btn-back i {
  vertical-align: middle;
  font-size: 2px * 14;
  font-weight: 300;
  margin-right: 15px;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.icon-arrow-left:before {
  content: "\e9d9";
}

h5,
.h5 {
  font-size: 2px * 8;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}
.sa-prog-grid {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.sa-prog-grid p {
  width: 100px;
}

p {
  margin: 2px * 2 + 1px 0;
  font-family: Avenir;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.sa-prog-grid span {
  color: #4fe765;
  margin-right: 5px;
}
.sa-prog-bar {
  width: calc(100% - 100px);
  background-color: #dde0e6;
  height: 4px;
  border-radius: 10px;
  position: relative;
}
.sa-prog-bar-per {
  background-image: linear-gradient(to right, #4fe765, #26a9e0);
  height: 4px;
  border-radius: 10px;
}
:is(.sa-card-no-border, .sa-card-shadow) {
  border: none;
  padding-left: 20px;
}
:is(.sa-card, .sa-card-shadow, .sa-card-no-border) {
  border-radius: 2px * 5;

  background-color: #fff;
  overflow: hidden;
  color: var(--sa-ebony);
  transition: 0.3s;
  padding: 2px * 10;
  margin-bottom: 2px * 10;
  position: relative;
}
:is(.sa-caregiver-work p, .sa-cargiver-task p, .sa-caregiver-in p) {
  color: #9b9b9b;
}

.sa-fw500 {
  font-weight: 500;
}
.sa-mb10,
.sa-smthng-wrg h5 {
  margin-bottom: 2px * 5;
}
.sa-primary-text {
  color: #26a9e0;
}
.sa-sel-car-v2-task {
  padding: 0 0 2px * 10 0;
}
h5,
.h5 {
  font-size: 2px * 8;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
}

* {
  box-sizing: border-box;
}
user agent stylesheet h5 {
  display: block;

  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
@media only screen and (max-width: 1366px),
  only screen and (max-device-width: 1366px) {
  .sa-col-md6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.sa-d-flex {
  display: -webkit-flex;
}
[class*="col"] {
  box-sizing: border-box;
  min-height: 1px;
  width: 2% * 50;
  padding-right: 2px * 5;
  padding-left: 2px * 5;
  position: relative;
}

.sa-cargiver-task {
  position: relative;
  padding-right: 122px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}
.sa-card-shadow {
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-top: 20px;
  border-radius: 5px;
}
:is(.sa-card-no-border, .sa-card-shadow) {
  border: none;
}
:is(.sa-card, .sa-card-shadow, .sa-card-no-border) {
  border-radius: 2px * 5;

  background-color: #fff;
  overflow: hidden;
  color: var(--sa-ebony);
  transition: 0.3s;
  padding: 2px * 10;
  margin-bottom: 2px * 10;
}

.sa-mb5 {
  margin-bottom: 5px;
}
h5,
.h5 {
  font-size: 2px * 8;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}
:is(.sa-caregiver-work p, .sa-cargiver-task p, .sa-caregiver-in p) {
  color: #9b9b9b;
}

p {
  margin: 2px * 2 + 1px 0;
}

.sa-cargiver-task h6 {
  color: #26a9e0;
}
.sa-mt20 {
  margin-top: 2px * 10;
}
h6,
.h6 {
  font-size: 2px * 7;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;

  font-weight: 600;
}
.sa-cargiver-task small {
  color: #26a9e0;
}
small {
  font-size: smaller;
}
.sa-img-cover {
  width: 2% * 50;
  height: 2% * 50;
  object-fit: cover;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
.sa-card-margin {
  padding: 10px;
}
.sa-fw500 {
  font-weight: 640;
}

.sa-sel-car-v2-task {
  padding: 20px;
}
.back-text {
  font-size: large;
}
