html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
input {
  border: none;
}
body {
  width: 144rem;
}
.add-details-72t {
  box-sizing: border-box;
  padding-top: 2.4rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.add-details-72t .group-182-DLp {
  margin: 0rem 10rem 3.3rem 10rem;
  width: calc(100% - 20rem);
  height: 6.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .logo-ioN {
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg {
  box-sizing: border-box;
  padding: 1.2rem 0rem 0.4rem 31.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg .group-10-yUQ {
  margin: 1.2rem 12.2rem 1.1rem 0rem;
  height: calc(100% - 2.3rem);
  display: flex;
  column-gap: 4.6rem;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg .group-10-yUQ .home-t5a {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg .group-10-yUQ .about-oiL {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-182-DLp
  .auto-group-xwyc-reg
  .group-10-yUQ
  .join-as-provider-kNg {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-182-DLp
  .auto-group-xwyc-reg
  .group-10-yUQ
  .get-care-5Qx {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg .group-179-cvg {
  margin: 0.1rem 5.7rem 0rem 0rem;
  width: 5.6rem;
  height: 2.6rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t .group-182-DLp .auto-group-xwyc-reg .auto-group-ezsr-3WC {
  width: 14.9rem;
  height: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8888888889;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #af2245;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t .auto-group-dwhz-hqe {
  box-sizing: border-box;
  padding: 2.4rem 10rem 4.7rem 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(175, 34, 69, 0.1000000015);
  flex-shrink: 0;
}
.add-details-72t .auto-group-dwhz-hqe .group-1261154093-2d2 {
  margin: 0rem 38.7rem 4.1rem 0rem;
  width: calc(100% - 38.7rem);
  height: 2.7rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154076-wEC {
  margin-right: 10.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154076-wEC
  .group-1261154072-Ujv {
  margin: 0rem 1.64rem 0.7rem 0rem;
  width: 2.7599rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154076-wEC
  .back-bZe {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154934-8pU {
  margin: 1.2rem 0rem 0.99rem 0rem;
  box-sizing: border-box;
  padding-right: 0rem;
  height: calc(100% - 2.1901rem);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154934-8pU
  .rectangle-39545-3gY {
  margin: 0rem 1.091rem 0.01rem 0rem;
  width: 23.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154934-8pU
  .rectangle-39546-m6k {
  margin: 0.01rem 1.091rem 0rem 0rem;
  width: 23.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154934-8pU
  .rectangle-39547-gUc {
  margin: 0.01rem 1.091rem 0rem 0rem;
  width: 23.7066rem;
  height: 0.5rem;
  background-color: #602d8a;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .group-1261154093-2d2
  .group-1261154934-8pU
  .rectangle-39548-QvQ {
  margin-top: 0.0099rem;
  width: 23.7066rem;
  height: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t .auto-group-dwhz-hqe .frame-37120-wfS {
  width: 580px;
  box-sizing: border-box;
  padding: 0.8rem 2.4rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 1.2rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-dwhz-hqe
  .frame-37120-wfS
  .search-your-lab-tests-packages-VBA {
  /* margin-right: 12.7rem; */
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t .auto-group-dwhz-hqe .frame-37120-wfS .line-1-CbN {
  margin-right: 2.2rem;
  width: 0rem;
  height: 3.7rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t .auto-group-dwhz-hqe .frame-37120-wfS .vector-Lha {
  margin-top: 0.1rem;
  margin-left: 0px;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t .auto-group-fd7r-3bz {
  box-sizing: border-box;
  padding: 2.4rem 10rem 5.7rem 10rem;
  /* width: 100%; */
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t .auto-group-fd7r-3bz .auto-group-dzfn-ZaL {
  margin-bottom: 5.2rem;
  width: 100%;
  height: 30.7rem;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg {
  margin-right: 4.4rem;
  box-sizing: border-box;
  padding: 2rem 3.2rem;
  width: 51.7rem;
  height: 19.2rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ {
  /* width: 36.5rem; */
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ
  .group-1261154660-mwJ {
  margin-right: 2.4rem;
  width: 16.7rem;
  height: 15.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ
  .group-1261154672-vJQ {
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ
  .group-1261154672-vJQ
  .zwaara-care-fmn {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ
  .group-1261154672-vJQ
  .laboratory-home-visit-cBE {
  font-size: 1.0052rem;
  font-weight: 700;
  line-height: 1.624999917;
  text-transform: capitalize;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .group-1261154677-WEg
  .group-1261154676-dKJ
  .group-1261154672-vJQ
  .view-selected-details-k2Y {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2 {
  width: 67.9rem;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-vgpe-omW {
  box-sizing: border-box;
  padding-bottom: 0.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-vgpe-omW
  .add-details-ZEt {
  margin-bottom: 1rem;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: capitalize;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-vgpe-omW
  .select-patient-t2G {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi {
  width: 100%;
  height: 22.1rem;
  position: relative;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .helth-check-pakages-9it {
  width: 17.1rem;
  height: 2.1rem;
  position: absolute;
  left: 2.4rem;
  top: 3.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .sar-400-Sxt {
  width: 6.7rem;
  height: 2.1rem;
  position: absolute;
  left: 57.1rem;
  top: 3.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .choose-one-or-more-patients-for-this-test-wue {
  width: 32.6rem;
  height: 2.4rem;
  position: absolute;
  left: 2.4rem;
  top: 7.7rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rectangle-39553-eJG {
  width: 67.9rem;
  height: 22.1rem;
  position: absolute;
  left: 0;
  top: 0;
  border: solid 0.05rem #888b9e;
  box-sizing: border-box;
  border-radius: 0.7rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rectangle-39669-AGc {
  width: 14.4rem;
  height: 7rem;
  position: absolute;
  left: 2.4rem;
  top: 12.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .aliyan-5eU {
  width: 4.9rem;
  height: 2.4rem;
  position: absolute;
  left: 7.2rem;
  top: 14.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rectangle-39672-12L {
  width: 14.4rem;
  height: 7rem;
  position: absolute;
  left: 18.6rem;
  top: 12.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rectangle-39673-jU8 {
  width: 14.4rem;
  height: 7rem;
  position: absolute;
  left: 34.8rem;
  top: 12.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rectangle-39674-Gix {
  width: 14.4rem;
  height: 7rem;
  position: absolute;
  left: 51rem;
  top: 12.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rahmat-QKN {
  width: 6.4rem;
  height: 2.4rem;
  position: absolute;
  left: 22.6rem;
  top: 14.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rahmat-KBS {
  width: 6.4rem;
  height: 2.4rem;
  position: absolute;
  left: 38.8rem;
  top: 14.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .rahmat-SG4 {
  width: 6.4rem;
  height: 2.4rem;
  position: absolute;
  left: 55rem;
  top: 14.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ {
  width: 10.1rem;
  height: 3.2rem;
  position: absolute;
  left: 55.4rem;
  top: 6.3rem;
  display: flex;
  column-gap: 1.3rem;
  align-items: center;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ
  .group-1171275049-esv {
  width: 3.2rem;
  height: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2259999381;
  color: #111535;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.2rem #888b9e;
  box-sizing: border-box;
  background-color: rgba(27, 43, 58, 0.1000000015);
  border-radius: 1.6rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ
  .item-1-WfE {
  margin-top: 0.3rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2228587189;
  color: #111535;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ
  .group-371-Er8 {
  width: 3.2rem;
  height: 100%;
  position: relative;
  border: solid 0.2rem #af2245;
  box-sizing: border-box;
  border-radius: 1.6rem;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ
  .group-371-Er8
  .ellipse-57-BmN {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 2px;
  top: 2.5px;
  background-color: #af2245;
  border-radius: 1.2rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dzfn-ZaL
  .auto-group-k7dv-4Z2
  .auto-group-ck56-pRi
  .group-1171275053-xVJ
  .group-371-Er8
  .item--6tL {
  width: 1.1rem;
  height: 2.3rem;
  position: absolute;
  left: 0.9rem;
  top: 0.4rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2259999381;
  color: #ffffff;
  font-family: Open Sans, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t .auto-group-fd7r-3bz .auto-group-dsgg-mzU {
  margin-bottom: 1.5rem;
  width: 67.9rem;
  height: 28rem;
  position: relative;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .helth-check-pakages-Wx4 {
  width: 17.1rem;
  height: 1.7rem;
  position: absolute;
  left: 2.4rem;
  top: 2rem;
  font-size: 1.605rem;
  font-weight: 500;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .if-you-have-medical-condition-allergies-or-taking-any-medication-please-mention-it-RZE {
  width: 52.1rem;
  height: 4.8rem;
  position: absolute;
  left: 2.4rem;
  top: 5.3rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .rectangle-39671-69a {
  width: 67.9rem;
  height: 28rem;
  position: absolute;
  left: 0;
  top: 0;
  border: solid 0.05rem #888b9e;
  box-sizing: border-box;
  border-radius: 0.7rem;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp {
  width: 62.5rem;
  height: 13.8948rem;
  position: absolute;
  left: 2.4rem;
  top: 12.5rem;
  row-gap: 1.4rem;
  display: flex;
  flex-direction: column;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154927-L3v {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154927-L3v
  .text-noteoptional-Uvp {
  margin: 0rem 39.9rem 0.1rem 0rem;
  font-size: 1.605rem;
  font-weight: 500;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154927-L3v
  .group-1261154924-zPN {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154927-L3v
  .group-1261154924-zPN
  .group-k7e {
  margin-right: 1.5rem;
  width: 1.6rem;
  height: 2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
  position: relative;
  top: -5px;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154927-L3v
  .group-1261154924-zPN
  .add-gmz {
  margin-top: 0.1rem;
  font-size: 1.605rem;
  font-weight: 500;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #888b9e;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154938-D1E {
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  width: 100%;
  height: 7rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: 0.024rem;
  color: #888b9e;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  background-color: #f6f7f9;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154926-t7N {
  margin-right: 0.3rem;
  width: calc(100% - 0.3rem);
  height: 2.0948rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154926-t7N
  .attach-document-R7J {
  margin: 0.005rem 41.9rem 0rem 0rem;
  font-size: 1.605rem;
  font-weight: 500;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154926-t7N
  .group-1261154925-Xg8 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154926-t7N
  .group-1261154925-Xg8
  .group-1261154923-tWg {
  margin-right: 1.1656rem;
  width: 1.1344rem;
  height: 2.0948rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .auto-group-dsgg-mzU
  .group-1261154928-cNp
  .group-1261154926-t7N
  .group-1261154925-Xg8
  .file-E4k {
  margin-top: 0.0052rem;
  font-size: 1.605rem;
  font-weight: 500;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #888b9e;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .auto-group-fd7r-3bz
  .by-clicking-continue-you-agree-to-our-terms-conditions-9Sc {
  margin: 0rem 11.2rem 2.4rem 0rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  flex-shrink: 0;
}

.add-details-72t
  .auto-group-fd7r-3bz
  .by-clicking-continue-you-agree-to-our-terms-conditions-9Sc
  .by-clicking-continue-you-agree-to-our-terms-conditions-9Sc-sub-1 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration-line: underline;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
}
.add-details-72t .auto-group-fd7r-3bz .frame-37121-wmn {
  margin-right: 15.2rem;
  width: 37.5rem;
  height: 5.3rem;
  overflow: hidden;
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN {
  box-sizing: border-box;
  padding: 6.083rem 26.6rem 6.7rem 10rem;
  width: 100%;
  height: 33.8rem;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .group-1261154099-h8g {
  margin: 0.617rem 22.767rem 1.733rem 0rem;
  height: calc(100% - 2.35rem);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .group-1261154099-h8g
  .group-1261154093-D72 {
  margin-bottom: 2.3rem;
  width: 12.8rem;
  height: 12.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .group-1261154099-h8g
  .group-1261154040-Yf6 {
  margin-left: 0.05rem;
  width: 20.2667rem;
  height: 4.1667rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-tjh2-sxG {
  margin-right: 8.85rem;
  width: 12.8167rem;
  height: 100%;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-tjh2-sxG .company-2aG {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t .group-1000002072-yyN .auto-group-tjh2-sxG .frame-37135-ZaC {
  margin-left: 0.0167rem;
  width: calc(100% - 0.0167rem);
  row-gap: 1.2rem;
  display: flex;
  flex-direction: column;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-tjh2-sxG
  .frame-37135-ZaC
  .home-VTr {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-tjh2-sxG
  .frame-37135-ZaC
  .about-q1v {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-tjh2-sxG
  .frame-37135-ZaC
  .join-as-provider-y88 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-tjh2-sxG
  .frame-37135-ZaC
  .get-care-Jw6 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-tjh2-sxG
  .frame-37135-ZaC
  .join-us-Toz {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-2ztr-CWg {
  margin: 0rem 12.617rem 3.3rem 0rem;
  width: 9.8rem;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-2ztr-CWg .laboratory-Y4k {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.add-details-72t .group-1000002072-yyN .auto-group-2ztr-CWg .frame-37136-H2L {
  width: 100%;
  row-gap: 1.3rem;
  display: flex;
  flex-direction: column;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-2ztr-CWg
  .frame-37136-H2L
  .general-test-2Ep {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-2ztr-CWg
  .frame-37136-H2L
  .blood-test-mTJ {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-2ztr-CWg
  .frame-37136-H2L
  .urine-test-7XA {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-2ztr-CWg
  .frame-37136-H2L
  .dna-test-Tqv {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-qrc4-CoW {
  margin-bottom: 7.8rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.add-details-72t .group-1000002072-yyN .auto-group-qrc4-CoW .contact-us-ka8 {
  margin-bottom: 2.2167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-qrc4-CoW
  .item-923489934721-Ha4 {
  margin: 0rem 0rem 0.8rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-qrc4-CoW
  .zwaarahealthcom-bak {
  margin: 0rem 0rem 0.9rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.add-details-72t
  .group-1000002072-yyN
  .auto-group-qrc4-CoW
  .riadh-2343-saudi-arabia-84t {
  margin-left: 0.0333rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

button {
  background-color: #af2245;
  border: none;
  color: #fff;
  padding: 7px, 15px;
  border-radius: 6px;
  cursor: pointer;
}
.btnAdd {
  padding: 150px, 0px;
}
.add-patient-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-Btn {
  margin-right: 8px;
}
.circle-Btn {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid white;
}
label {
  display: flex;
}

.group-1261154072-sPS {
  margin: -0.8rem 1.64rem 0.1rem 0rem;
  width: 2.7599rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}