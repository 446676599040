.reg-v2 .body-cnt {
    padding: 20px;
}
.body-cnt, .login {
    margin-top: 20px;
    max-height: calc(100vh - (10vh + 100px));
    overflow: hidden;
    overflow-y: auto;
    width: 35%;
    margin-left: 33%;
}
.dialog-close {
    cursor: pointer;
    position: absolute;
    right: -60px;
    top: 0;
    background: #FFF;
    border: none;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    line-height: 30px;
    font-size: 20px;
}
.dialog-close i {
    appearance: none;
    color: #000;
    vertical-align: middle;
    border: 2px solid #000;
    padding: 8px;
    border-radius: 50%;
}
.last-child:last-child {
    margin-bottom: 0;
}
.card-primary-light {
    background-color: #EAF7FC;
}
:is(.card-no-border,.card-shadow) {
    border: none;
}
:is(.card,.card-shadow,.card-no-border) {
    border-radius: calc(2px*5);
    overflow: hidden;
    transition: .3s;
    padding: calc(2px* 10);
    position: relative;
}
.mob-block {
    display: none;
}
.input-group-vert {
    flex-direction: column;
    position: relative;
    /* border: 1px solid #DDE0E6;
    border-radius: 0.5rem; */
}
.input-group {
    display: flex;
}
.input-group-vert label {
    color: #111535;
    /* padding-left: 10px;
    padding-top: 10px; */
    margin-bottom: 10px;
}
.mb20 {
    margin-bottom: calc(2px* 10);
}
.label{
    display: block;
    line-height: normal;
    font-size: calc(2px* 6 + 1px);
    font-weight: normal;
    transition: all 0.2s;
    touch-action: manipulation;
}
.form-group {
    position: relative;
    margin-bottom: calc(2px* 10);
    display: block;
}
.form-img {
    width: 30px;
    right: auto;
    left: 10px;
    top: 5px;
}
.form-control {
    background: var(--sa-light);
    height: calc(2px* 20);
    line-height: calc(2px* 20);
    color: #000;
    padding: 0 35px 0 15px;
    width: calc(2%* 50);
    border-radius: calc(2px*3);
    box-sizing: border-box;
    appearance: none;
    white-space: normal;
    text-overflow: ellipsis;
    outline: none;
}
.input-group-vert .icon-down-arrow {
    top: 20px;
}
.form-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}
.icon-down-arrow:before {
    content: "\e92f";
}
.input-group-vert .form-group {
    margin-bottom: 0;
}
.modal-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.cuntry-plholder {
    position: absolute;
    left: 10px;
    top: 55%;
    transform: translateY(-50%);
    opacity: 0.7;
}
.input-group-vert .form-group-line .form-control.input-no-border {
    border-top: 1px solid #DDE0E6;
    border-radius: 0 0 0.5rem 0.5rem;
    height: 70px;
}
.input-group-vert .form-group .form-control.input-no-border {
    position: unset;
    border: none;
    padding-left: 35px;
    /* margin-top: 8px; */
}
.input-group > .form-control {
    position: relative;
    padding: 0 15px;
}
.f-size12 {
    font-size: calc(2px* 6 + 1px);
}
.link {
    color: #AF2245;
    text-decoration: underline;
    cursor: pointer;
}
.btn-block {
    display: block;
    width: calc(2%* 50);
}
.btn-lg {
    padding: 0 25px;
    font-size: calc(2px* 8);
    line-height: calc(2px* 25);
    height: calc(2px* 25);
    border-radius: calc(2px* 5);
}
.btn-primary {
    color: #FFF;
    background-color: #26A9E0;
}
:is(.btn,.btn-block,.btn-primary) {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background 0.8s;
}
.btn-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 1%;
    background: #FFF;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    margin-left: 100%;
    margin-right: -8px;
}
.btn-close i{
    appearance: none;
    color: #000;
    border: 2px solid #000;
    padding: 4px;
    border-radius: 15%;
}
.btn-space{
    height: 30px;
}

.z-sign-btn {
    cursor: pointer;
    position: fixed;
    background: #fff;
    border: none;
    border-radius: 15px;
    width: 51px;
    height: 51px;
    line-height: 30px;
    font-size: 20px;
    margin-top: -35px;
    margin-left: 472px;
}

.z-sign-btn i {
    appearance: none;
    color: #000;
    vertical-align: text-top;
    border: 2px solid #000;
    padding: 8px;
    border-radius: 50%;
}

.signup_link{
    color: #AF2245;
}