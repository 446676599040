.mob-block{
    display: none;
}
.btn-back {
    font-size: calc(2px* 9);
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 calc(2px* 10);
    line-height: calc(2px* 10);
    vertical-align: middle;
    background: transparent;
    margin-right: 15px;
    color: #000;
}
.card-shadow.service-prov {
    border-radius: 0;
    padding: 50px;
}
.service-prov {
    width: 70%;
    margin: 40px auto;
}
.card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0,0,0,.1);
}
:is(.card-no-border,.card-shadow) {
    border: none;
}
.text-center {
    text-align: center;
}
.h3 {
    font-size: 35px;
}
.glob-prgh p {
    font-weight: 300;
    color: #474554;
}
p {
    margin: calc(2px* 2 + 1px) 0;
}
.service-prov .track-order {
    justify-content: center;
    margin: 15px auto 60px;
    width: 40%;
}
.track-order {
    display: flex;
    position: relative;
}
ul {
    padding: 0;
    list-style: none;
}
.service-prov .track-order:before {
    left: 0;
    right: 0;
    height: 2px;
    top: 15px;
}
.track-order:before {
    content: '';
    width: 100%;
    background-color: #eff4fd;
    position: absolute;
    z-index: 0;
}
.track-order li {
    width: 33.3333%;
    text-align: center;
    position: relative;
    padding-top: 30px;
    font-weight: 500;
}
li {
    display: list-item;
}
.track-order li:last-child {
    text-align: right;
}
.track-order li.packed::before {
    left: -100%;
}
.track-order li.packed::before, .track-order li.delivered:before {
    content: '';
    position: absolute;
    right: 0;
    background: #26A9E0;
    width: 0;
    z-index: 0;
    height: 2px;
    top: 14px;
    transition: width 1s linear;
}
.service-prov .track-order li span.fill-dot {
    border: 2px solid #26A9E0;
}
.service-prov .track-order li .track-dot {
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    display: block;
    border: 3px solid #eff4ff;
    text-align: center;
    position: absolute;
    top: 0;
}
.fill-dot::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #26A9E0;
    bottom: 50%;
    transform: translateY(50%);
    margin: 0 auto;
}
h3 {
    font-size: calc(2px* 10);
}
.track-order li:nth-child(2) .track-dot {
    left: 50%;
    transform: translateX(-50%);
}
.service-prov .slct-card {
    margin-top: 20px;
}
.slct-card {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.service-prov .slct-card li {
    width: calc(100%/3 - (1 - 1/3)*15px);
}
.track-order li:last-child .track-dot {
    right: 0;
}
.slct-card li {
    font-size: 12px;
    margin-right: 0;
}
.slct-card .active {
    border-radius: 10px;
    background-color: #eaf7fc;
    border: 1px solid #6cc4ea;
}
.slct-card li a:hover {
    background: #c7e3ee;
}
.service-prov .slct-card li a {
    height: 180px;
    padding: 30px;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid transparent;
}
.slct-card li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90px;
    min-width: 100px;
    border-radius: calc(2px * 5);
    box-shadow: 0 0 calc(2px * 5) 0 rgba(0, 0, 0, 0.1);
    background: #FFF;
}
a {
    text-decoration: none;
    outline: none;
    color: #000;
}
.service-prov .slct-card li a i.doc-spe {
    background-position: -180px 0;
}
.service-prov .slct-card li a i {
    width: 44px;
    height: 54px;
    margin-bottom: 15px;
}
.sprite-new {
    content: '';
    background: url(https://sanar-assets.com/images/sprite/v5/sprite.svg) no-repeat;
    display: block;
    background-size: 1600px;
}
.service-prov .slct-card li a i.hos-cen {
    background-position: -224px 0;
}
.service-prov .slct-card li a i.in-company {
    background-position: -269px 0;
}
.link{
    display: contents;
    width: 100%;
}
@media only screen and (max-width: 768px), only screen and (max-device-width: 768px){
    .card-shadow.service-prov {
        border-radius: 0;
        padding: 20px;
        width: 721px;
    }
}