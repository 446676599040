/* .join-adv{
    background: blue;
} */
.h5 {
    font-size: 22px;
}
.mb20 {
    margin-bottom: calc(2px* 10);
}
.top120{
    width: 1160px;
    margin: 0 auto;
    max-width: 1160px;
}
.success-text {
    color: #4FE765;
}
.h4 {
    font-size: 28px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(2px* -5);
    margin-left: calc(2px* -5);
}
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.gutter50 [class*=col] {
    padding-right: calc(2px*25);
    padding-left: calc(2px*25);
}

.join-doc-list li {
    margin-bottom: 30px;
}
.gap30 {
    gap: 30px;
}
.join-doc-list li h5 {
    margin-bottom: 20px;
}
.pl85 {
    padding-left: calc(2px*40 + 5px);
}
.text-right {
    text-align: right;
}
.btn-lg {
    padding: 0 25px;
    font-size: calc(2px* 8);
    line-height: calc(2px* 25);
    height: calc(2px* 25);
    border-radius: calc(2px* 5);
}
.btn-primary {
    color: #FFF;
    background-color: #26A9E0;;
}
:is(.btn,.btn-block,.btn-primary) {
    display: inline-flex;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background 0.8s;
}
.img-cover {
    width: calc((2%)* 50);
    height: calc((2%)* 50);
    object-fit: cover;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
figure {
    display: block;
    margin: 0;
    padding: 0;
}
.icon-bg {
    font-size: 30px;
    width: 50px;
    height: 48px;
    position: relative;
    color: #020288;
}
.icon-bg.icon-hospital3:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.icon-bg:after {
    content: '';
    position: absolute;
    right: 0;
    left: 8px;
    top: 0;
    background: url(https://sanar-assets.com/images/static/v5/icon-bg.svg) no-repeat;
    background-size: 100%;
    width: 54px;
    height: 52px;
}
.icon-bg.icon-digital-nomad:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.icon-bg.icon-purse:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.icon-bg.icon-date-time:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.icon-bg.icon-target:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.icon-bg.icon-skill:before{
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 50px;
}
.h2 {
    font-weight: 600;
}
@media only screen and (max-width: 768px), only screen and (max-device-width: 768px){
    body{
       /* overflow-x: hidden; */
    }
    .top120 {
        width: 1160px;
        margin: 0 auto;
        max-width: 1160px;
        margin-top: 40px;
        margin-left: 30px;
        width: 95%;
    }
    .join-adv {
        margin-bottom: 40px;
    }
    .sa-col-xs-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .static h2 {
        font-size: 18px;
    }
    .join-doc-list button {
        width: 100%;
    }
    .h5 {
        font-size: 1rem;
    }
    .h4 {
        font-size: calc(2px* 9);
    }
    .col-6,
    .col-md-6,
    .col-sm-6 {
        width: 100%;
    }

    .mob-none {
        display: none;
    }
}