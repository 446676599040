.card-shadow.service-prov {
    border-radius: 0;
    padding: 50px;
}
.service-prov {
    width: 70%;
    margin: 40px auto;
}
.card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0,0,0,.1);
}
:is(.card-no-border,.card-shadow) {
    border: none;
}
.mob-block{
    display: none;
}
.btn-back i {
    vertical-align: middle;
    font-size: calc(2px* 14);
    font-weight: 300;
    margin-right: 15px;
}
.tit {
    margin-bottom: calc(2px* 10);
    text-align: left;
}
.glob-prgh p {
    font-weight: 300;
    color: #474554;
}
.service-prov .track-order {
    justify-content: center;
    margin: 15px auto 60px;
    width: 40%;
}
.track-order {
    display: flex;
    position: relative;
}
.service-prov .track-order:before {
    left: 0;
    right: 0;
    height: 2px;
    top: 15px;
}
.track-order:before {
    content: '';
    width: 100%;
    background-color: #eff4fd;
    position: absolute;
    z-index: 0;
}
.track-order .accepted {
    text-align: left;
}
.service-prov .track-order li .track-dot:before {
    background: #26A9E0;
    font-size: 8px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    position: absolute;
    z-index: 9;
    top: -3px;
    width: 32px;
    left: -3px;
    height: 32px;
    border-radius: 50%;
}
.track-order li {
    width: 33.3333%;
    text-align: center;
    position: relative;
    padding-top: 30px;
    font-weight: 500;
}
.track-order li.packed::before {
    left: -100%;
}
.track-order li.packed::before, .track-order li.delivered:before {
    content: '';
    position: absolute;
    right: 0;
    background: #26A9E0;
    width: 0;
    z-index: 0;
    height: 2px;
    top: 14px;
    transition: width 1s linear;
}
.service-prov .track-order li span.fill-dot {
    border: 2px solid #26A9E0;
}
.service-prov .track-order li .track-dot {
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    display: block;
    border: 3px solid #eff4ff;
    text-align: center;
    position: absolute;
    top: 0;
}
.fill-dot::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #26A9E0;
    bottom: 50%;
    transform: translateY(50%);
    margin: 0 auto;
}
.track-order li:last-child {
    text-align: right;
}
.track-order li:last-child .track-dot {
    right: 0;
}
.cursor {
    cursor: pointer;
}
.form-group-lg .form-img-jb {
    top: 10px;
}
.form-img-jb{
    width: 30px;
    right: auto;
    left: 10px;
    margin-top: -8px;
}
.ml10 {
    margin-left: calc(2px* 5);
}
.mr10{
    margin-right: calc(2px* 5);
} 
.form-icon-jb {
    position: absolute;
    right: 15px;
    top: 13px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}
.form-icon-cc{
    position: absolute;
    right: 15px;
    top: 35px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}
.inp-flag{
    padding-left: 50px;
    cursor: pointer;
}