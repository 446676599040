.dialog-content,
.body-cnt,
.login {
  max-height: calc(100vh - (10vh + 100px));
  overflow: hidden;
  overflow-y: auto;
  padding: 1px 20px;
  margin: 0%;
  width: 100%;
}

.tit {
  margin-bottom: 2px * 10;
  text-align: left;
}
h4,
.h4 {
  font-size: 2px * 9;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h5 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}

* {
  box-sizing: border-box;
}
user agent stylesheet h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.gray-light-bg {
  background-color: #f4f6fa;
}
:is(.card-no-border, .card-shadow) {
  border: none;
}

:is(.card, .card-shadow, .card-no-border) {
  border-radius: 2px * 5;

  overflow: hidden;
  color: var(--sa-ebony);
  transition: 0.3s;
  padding: 2px * 10;
  margin-bottom: 2px * 10;
  position: relative;
}

h6,
.h6 {
  font-size: 2px * 7;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
h3,
h4,
h5,
.h6 {
  margin: 0;
  color: #002a56;
  font-weight: 600;
}

h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

user agent stylesheet ul {
  display: block;

  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.unorder-list li {
  list-style-type: disc;
  margin: 0 0 2px * 5 2px * 8;
  color: #000;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
div {
  display: block;
}

.dark-text {
  color: #000;
}
p {
  margin: calc(var(--sa-pixel) * 2 + 1px) 0;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
user agent stylesheet p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.unorder-list li {
  list-style-type: disc;
  margin: 0 0 2px * 5 2px * 8;
  color: #000;
  margin-left: 20px;
  line-height: 2.5;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
