.bb-business-bg{
    position: relative;
    background: url(https://sanar-assets.com/images/static/new-theme/sanar-business-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 0;
}

.bb-pos-rel{
    position: relative;
}

.bb-container{
    width: 1160px;
    margin: 0 auto;
    max-width: 1160px;
}

.bb-row{
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: wrap;
    /* margin-right: calc(var(--sa-pixel)* -5);
    margin-left: calc(var(--sa-pixel)* -5); */
}

.bb-tit h1{
    margin-bottom: 20px;
    text-align: left;
    font-size: 54px;
}

.bb-busnes h2{
    text-align: start;
    font-size: 44px;
    font-weight: 650;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    display: block;
    margin: 0;
}

.bb-description {
    font-weight: 350;
    color: #474554;
    margin: 5px;
   padding-bottom: 20px;
   padding-top: 20px;
   padding-right: 20px;
}

.bb-mt30{
    margin-top: calc(var(--sa-pixel)* 15);
    border-radius: 10px;
    height: 43px;
    padding-top: 10px;
    width: 140px;
}



.bb-ministry{
    display: flex;
    margin-top: 120px;
}

.bb-ministry img{
    margin-right: 10px;
}

.bb-avtar-sm{
    width: 34px;
    height: 34px;
    line-height: 34px;
}



.bb-business-section{
    width: 100%;
}





/* .container {
    padding-bottom: 40px;
    padding-top: 80px;
} */


.wb-title{
    padding-top: 30px;
    margin-top: 100px;
    font-weight: 300px;
}

.wb-subtitle{
    margin-left: 320px;
}

.wb-card-title {
    color: #002A56;
    font-weight: 600;
    font-size: 20px;
    padding-right: 25px;
}

.wb-card-text {
    color: black;
    font-size: 13px;
}

.bs-card {
    padding-bottom: 50px;
    padding-top: 50px;
    width: 310px;
    padding-left: 57px;
}

.wb-card {
    position: static;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 100px;
    height: 400px;
    width: 285px;
    top: 50px;
    left: 23px;
}

.wb-treatment-card {
    width: 100%;
    padding: 30px;

}

.wb-treatment-card i {
    font-size: 40px;
    margin: 10px 0;
    display: block;
    margin-left: -30px;
}

.wb-sprite-new {
    content: '';
    background: url(https://sanar-assets.com/images/sprite/v5/sprite.svg) no-repeat;
    background-size: 1600px;
}



.wb-journey {
    background-position: 2px 0 !important;
    width: 50px;
    height: 35px;
}

.wb-emp-success {
    background-position: -357px 0 !important;
    width: 44px;
    height: 50px;
}

.wb-enhance {
    background-position: -314px 0 !important;
    width: 43px;
    height: 49px;
}

.wb-money-fill {
    background-position: -136px 0 !important;
    width: 45px;
    height: 41px;
}



.b-card:hover {
    transform: scale(1);
    box-shadow: 0 10px 20px #002A56, 0 4px 4px rgba(0, 0, 0, .06);
    background-color: #26a9e0;
    color: white;
    text-transform: uppercase;
    height: auto;
}

.b-card:hover h5 {
    color: white;
}

.b-card:hover p {
    color: white;
}




/* .bp-container {
    padding-bottom: 40px;
    padding-top: 80px;
} */

.bp-image {
    height: 570px;
    width: 350px;
}

.bp-title {

    bottom: 550px;
    right: 1050px;
    color: #002A56;
    padding-top: 4px;
    padding-left: 66px;
    padding-right: 20px;
    text-align: left;
    margin: 0;
    font-weight: 600;
    font-size: 44px;
}


.bp-decsription {
    color: grey;

    bottom: 350px;
    right: 777px;
    color: grey;
    padding-top: 10px;
    padding-left: 66px;
    padding-right: 20px;
    text-align: left;
    margin: 0;
    font-weight: 600;
}

/* .bp-description-list {
    text-align: left;
    padding-inline-start: 40px;
    padding-left: 150px;
    padding-right: 50px;
    padding-top: 50px;
    list-style: none;
} */

.bp-mt50 {
    margin-top: 20px;
    padding-left: 70px;
}

.bp-groups li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.bp-groups li span{
    margin-left: 10px;
    color: #ACA9BB;
    font-weight: 300;
    font-size: 14px;
}

.bp-groups i {
    color: #fff;
    background-color: #4fe765;
    padding: 8px 6px;
    border-radius: 50px;
    font-size: 10px;
    margin-right: 10px;
}





/* .be-container {
    padding-left: 147px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
} */

/* .be-title {
    position: absolute;
    top: 307%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 42px;
    margin-bottom: 20px;
    font-size: 44px;
    text-align: center;
}

.be-description {
    position: absolute;
    top: 315%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
} */

/* .b-join{
    margin-bottom: 50px;
    margin-top: 50px;
} */

.cb-emp-join-sanar-card {
    background: url(https://sanar-assets.com/images/static/new-theme/serviceproviderbg.svg) no-repeat;
    color: #FFF;
    position: relative;
    margin-bottom: 80px;
    height: 420px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: 75px;
    margin-right: 75px;
}

.cb-emp-top120 {
    margin-top: 120px;
}

.cb-emp-join-sanar-card h2 {
    color: #FFF;
    font-size: 42px;
    margin-bottom: 15px;
    font-weight: 400;
}

.cb-emp-join-sanar-card p {
    color: #FFF;
    margin: 0 auto 30px;
}