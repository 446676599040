.nv-content {
    box-sizing: border-box;
    padding: 30px 0 0;
}

.z-btn-back {
    margin-bottom: 0;
    display: flex;
    font-size: 18px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 20px;
    line-height: 20px;
    vertical-align: middle;
    background: transparent;
    margin-right: 15px;
    color: black;
    font-weight: 500;
}

.z-btn-back i {
    vertical-align: middle;
    font-size: 20px;
    font-weight: 600;
    margin-right: 16px;
}

.nv-input-group {
    display: flex;
}

.nv-location-label {
    align-items: center;
    position: absolute;
    top: 88px;
    right: 94px;
    left: auto;
    margin-right: 0;
    width: 24%;
    max-width: calc(100% - 350px);
    border: 1px solid #cccccc;
    border-radius: 8px;

}

.nv-input-group .nv-btn {
    position: relative;
    z-index: 2;
    border-radius: 0 0.5rem 0.5rem 0;

}

.nv-location-label .nv-btn {
    width: calc(100% - 45%);
    text-align: left;
    color: #26a9e0;
    padding: 0 5px 0 10px;
    border: 1px solid #00000000;
    background-color: #00000000;

}

.nv-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    display: inline-block;
    line-height: 40px;
}

.nv-location-label .icon-location2 {
    margin-left: 10px;
    color: #4fe765;
}

.nv-location-label .nv-form-control {
    padding-right: 30px;
    border: none;
    color: #26a9e0;
}

.nv-input-group>.nv-form-control {
    position: relative;
    padding: 0 15px;
}

.nv-cursor {
    cursor: pointer;
}

.nv-form-control {
    background: #fff;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    box-sizing: border-box;
    appearance: none;

}

.nv-form-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}


.z-doc-prog {
    margin-top: 35px;
    padding-bottom: 35px;
}

.z-doc-progress {
    margin-left: 0px;
}

.z-doc-progress span {
    color: #4FE765;
    width: 220px;
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    margin-top: -10px;
}

.nv-doc-prog-bar {
    width: 1230px;
    margin-left: 100px;

}



.nv-col8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.nv-col6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.z-filter-title {
    margin-bottom: 20px;
    margin-top: 2px;
    text-align: left;
    font-weight: 700;
}

.nv-form-group {
    position: relative;
    margin-bottom: 20px;
    display: block;
}

.nv-nurse .nv-form-icon {
    right: unset;
    left: 15px;
}

.nv-form-icon {
    position: absolute;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}

.nv-nurse .nv-form-control {
    padding: 0 15px 0 40px;
}

.nv-form-control {
    background: #fff;
    height: 40px;
    line-height: 40px;
    color: #000;
    border: 1px solid #aca9bb;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    -moz-appearance: none;
    appearance: none;
    white-space: normal;
    text-overflow: ellipsis;
}



.nv-card-list-box {
    display: flex;
    position: relative;
}

.nv-card-list>li {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: var(--sa-border-solid) var(--sa-dark-150);
    transition: .3s;
    font-size: 13px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.nv-bold {
    font-weight: bolder;
    margin-top: 3px;
}

.nv-nurse-checkbox li {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
    border: none;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.nv-nurse-checkbox .nv-doct-det {
    padding-right: 30px;
    width: 100%;
    padding-left: 8px;
}

.nv-doct-det {
    position: relative;
}

.nv-gray-lgt-clr {
    color: #9b9b9b;
}

.nv-ml5 {
    margin-left: 5px;
}

.nv-primary-text {
    color: #26a9e0;
}

.nv-nurse-checkbox .nv-light-text {
    background: #26a9e0;
    padding: 3px 10px;
    display: inline;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 300;
    border-radius: 7px;
    margin-top: 6px;
    height: 25px;
}

.nv-light-text {
    color: #fff;
}

.nv-chkbox {
    margin-left: 790px;
    content: '';
    position: absolute;
    top: 0;
    width: 16px;
    border: 1px solid #CCCCCC;
    height: 16px;
    background: #00000000;
    z-index: 1;
    border-radius: 4px;
}

.nv-nurse-title {
    color: #000;
    display: block;
    line-height: normal;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;

}

.nv-card-nurse-title {
    color: #000;
    display: block;
    line-height: normal;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    padding: 8px;
}

.hidden {
    display: none;
}

.nv-nurse .nv-status-card {
    margin: 15px 0 0px 0;
    padding: 10px;
    display: inline;
    background-color: #edf8ff;
}

.nv-status-card {
    background: #DFEFFF;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
}

.nv-col4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.nv-selected-data {
    background-color: #edf8ff;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    color: var(--sa-ebony);
    transition: .3s;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.nv-card-shadow {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    transition: .3s;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.nv-card-chkbox {
    margin-left: 790px;
    content: '';
    position: absolute;
    top: 0;
    width: 16px;
    border: 1px solid #CCCCCC;
    height: 16px;
    background: #00000000;
    z-index: 1;
    border-radius: 4px;
    right: 13px;
    top: 22px;
}

.nv-btn-block {
    display: block;
    width: 100%;
    border: none;
    border-radius: 7px;
    height: 45px;
}

.nv-btn-primary {
    color: #fff;
    background-color: #26a9e0;
}








.z-browser-header {
    padding: 20px 0;
}

.z-browser-header .z-btn-back {
    margin-bottom: 0;
    display: flex;
    font-size: 18px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 20px;
    line-height: 20px;
    vertical-align: middle;
    background: transparent;
    margin-right: 15px;
    color: black;
    font-weight: 500;
}

.z-btn-back i {
    vertical-align: middle;
    font-size: 20px;
    font-weight: 600;
    margin-right: 16px;
}

.z-srch-field {
    width: 450px;
    position: relative;
    margin-right: 30px;
    margin-left: 100px;
    margin-top: -48px;
}

.z-form-icon-left {
    right: auto;
    left: 15px;
}

.z-form-icon {
    position: absolute;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: black;
    font-size: 16px;
}

/* .horizontal-calendar {
    width: 500px;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    margin-left: 680px;
    margin-top: -46px;
} */

.z-date-list {
    position: relative;
    text-align: left;
    overflow: hidden;
    overflow-x: auto;
}

.z-date-list-scroll {
    position: relative;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    display: flex;
}

/* .horizontal-calendar .z-date-item {
    position: relative;
    width: 50px;
    padding: 0;
    line-height: normal;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    flex-shrink: 0;
    font-weight: 500;
} */

/* .horizontal-calendar .z-date-item-day {
    color: #7F7F7F;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3px;
    margin-top: 5px;
} */

/* .horizontal-calendar .z-date-item-date {
    color: #7F7F7F;
    font-size: 12px;
    font-weight: 800;
    line-height: 24px;
} */

/* .z-date-picker {
    position: relative;
    margin-left: 1074px;
    margin-top: -52px;
} */

.hvd-custom-input {
    padding-right: 0px;
    padding-left: 48px;
    padding-top: 5px;
    margin-left: 66px;
    margin-top: -10px;
    border-radius: 5px;
    border: 0px #0c8af8 solid;
    background-color: white;
    height: 37px;
    width: 145px;
    box-shadow: 0 0 2px #0074d9;
    font-size: larger;
}

/* .z-cal {
    position: absolute;
    top: 14%;
    right: 125px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #26a9e0;
} */


.z-doc-prog {
    margin-top: 30px;
}

.z-doc-progress {
    margin-left: 4px;
}

.z-doc-progress span {
    color: #4FE765;
    width: 220px;
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    margin-top: -10px;
}

.z-doc-prog-bar {
    width: 1188px;
    margin-left: 100px;

}

.z-result {
    padding: 20px 0;
}

.z-filter {
    border-radius: 10px;
    background-color: #f4f9ff;
    padding: 20px;
}

.z-filter-title {
    margin-bottom: 20px;
    margin-top: 2px;
    text-align: left;
    font-weight: 700;
}

.z-filter-list {
    margin: 0 -20px;
    padding: 20px;
}

.z-primary-title {
    color: #26A9E0;
}

.form-check-input {
    height: 20px;
    width: 20px;
}

.form-check-label {
    margin-left: 10px;
    margin-top: 2px;
    /* font-size: 16px; */
    /* font-weight: 400;
    font-family: 'Times New Roman', Times, serif; */
}

.z-doctor-card-list li {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid #DDE0E6;
    transition: 0.3s;
    font-size: 12px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.z-card-list-box {
    display: flex;
    position: relative;
}

.z-doc-avtar-lg {
    width: 100px;
    height: 100px;
}

.z-doc-avtar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #DFEFFF;
    border-radius: 8px;
    position: relative;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}

.z-doc-img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.z-doctor-details {
    display: flex;
    position: relative;
    width: calc(100% - 100px);
    padding-left: 15px;
}

.z-doctor-details-data {
    width: 75%;
    border-right: 1px dashed #9B9B9B;
    padding-right: 20px;
}

.z-doctor-details-data h6 {
    color: #26A9E0;
    font-weight: 700;
}

.z-desig {
    margin-top: 3px;
    font-size: 13px;
    font-weight: 600;
    line-height: 2;
}

.z-split p {
    color: #9B9B9B;
    line-height: 20px;
    font-size: 13px;
}

.z-split p i {
    font-size: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.z-dark {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.z-docvisit {
    font-weight: 600;
}

.z-doctor-price {
    width: 25%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.z-price-tag {
    word-break: break-word;
    color: #26A9E0;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
}

.z-time-carousel-slide {
    position: relative;
}

.z-time-carousel {
    margin-top: 16px;
    display: flex;
    overflow: hidden;
    width: 100%;
    scroll-snap-type: mandatory;
    gap: 2px;
}

.z-time-carousel li {
    transition: left .4s cubic-bezier(.47, .13, .15, .89);
    border: none;
    margin-left: 20px;
    padding-left: 20px;
    padding-right: 90px;
    margin-right: -32px;
    margin-top: -30px;
    margin-bottom: -15px;
}

.z-time-carousel li p:hover {
    background-color: #26A9E0;
}

.z-time-slot {
    padding: 5px 14px;
    border-radius: 4px;
    border: 1px solid #26A9E0;
    cursor: pointer;
    font-weight: 500;
    width: 81px;
    margin: 10px 5px 10px -1px;
}

.z-time-prev {
    margin-left: 0;
    position: absolute;
    height: 30px;
    top: 16px;
    background: #E0EDFA;
    cursor: pointer;
    border-radius: 4px;
    width: 34px;
    border: 1px solid #26A9E0;
}

.z-time-next {
    margin-left: 784px;
    position: absolute;
    height: 30px;
    top: 16px;
    background: #E0EDFA;
    cursor: pointer;
    border-radius: 4px;
    width: 34px;
    border: 1px solid #26A9E0;
}

.z-Department {
    border-top: 10px solid #FFF;
    margin: 0 -20px;
    padding: 20px;
}

.z-speaking {
    border-top: 10px solid #FFF;
    margin: 0 -20px;
    padding: 20px;
}

.z-classification {
    border-top: 10px solid #FFF;
    margin: 0 -20px;
    padding: 20px;
}

.z-filter label {
    margin-top: 15px;
    width: 100%;
}

.z-result .hvd-location-label {
    right: 10px;
}

.hvd-input-group {
    display: flex;
}

.hvd-location-label {
    align-items: center;
    position: absolute;
    top: 0;
    left: auto;
    margin-right: 0;
    max-width: calc(100% - 350px);
    border: 1px solid #cccccc;
    border-radius: 8px;

}

.hvd-input-group .hvd-btn {
    position: relative;
    z-index: 2;
    border-radius: 0 0.5rem 0.5rem 0;

}

.hvd-location-label .hvd-btn {
    width: calc(100% - 45%);
    text-align: left;
    color: #26a9e0;
    padding: 0 5px 0 10px;
    border: 1px solid #00000000;
    background-color: #00000000;

}

.hvd-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    display: inline-block;
    line-height: 40px;
}

.hvd-location-label .icon-location2 {
    margin-left: 10px;
    color: #4fe765;
}

.hvd-location-label .hvd-form-control {
    padding-right: 30px;
    border: none;
    color: #26a9e0;
}

.hvd-input-group>.hvd-form-control {
    position: relative;
    padding: 0 15px;
}

.hvd-cursor {
    cursor: pointer;
}

.hvd-form-control {
    background: #fff;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    box-sizing: border-box;
    appearance: none;

}

.hvd-form-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}

.nv-font-normal {
    font-weight: normal;
}

.nv-gray-dark-text {
    color: #86909d;
}

.nv-link {
    color: #26a9e0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;

}



.nvc-dialog {
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    background: rgba(0, 0, 0, 0.35);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nvc-dialog-overflow-unset {
    overflow: unset;
}

.nvc-dialog-body {
    background-color: #fff;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    position: relative;
    width: 45%;
    z-index: 102;
    margin: auto;
    border-radius: 10px;
    max-height: 90vh;
    padding: 50px 30px;
}

.nvc-dialog-close {
    cursor: pointer;
    position: absolute;
    right: -60px;
    top: 0;
    background: #fff;
    border: none;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    line-height: 30px;
    font-size: 20px;
}

.nvc-dialog-close i {
    appearance: none;
    color: #000;
    vertical-align: middle;
    border: 2px solid #000;
    padding: 8px;
    border-radius: 50%;
}

.nvc-body-cnt {
    max-height: calc(100vh - (10vh + 100px));
    overflow: hidden;
    overflow-y: auto;
    padding: 1px 20px;
}

.nvc-tit {
    margin-bottom: 20px;
    text-align: left;
    font-size: 18px;
    margin: 0;
    color: #002a56;
    font-weight: 600;
    padding-bottom: 20px;
}

.nvc-gray-light-bg {
    background-color: #f4f6fa;
    overflow: hidden;
    transition: .3s;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
}

.nvc-box-title {
    padding-bottom: 8px;
    color: #002a56;
    font-weight: 600;
}

.nvc-unorder-list li {
    list-style-type: disc;
    margin: 0 0 10px 16px;
    color: #000;
    background-color: #f4f6fa;
    border: none;
    font-weight: 500;
    font-size: 14px;
}

.nvc-dark-text {
    color: #000;
    font-weight: 500;
    font-size: 14px;

}

.nvc-unorder-list-admin li {
    list-style-type: disc;
    margin: 0 0 10px 16px;
    border: none;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
}