.z-carousel-container{
    /* background: linear-gradient(45deg, rgba(79, 231, 101, 0.25) 0%, rgba(38, 169, 224, 0.25) 100%); */
    /* padding: 140px 0; */
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-start,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

.z-carousel{
    display: flex;
    overflow: hidden;
    overflow-x: hidden;
    width: 100%;
    scroll-snap-type: x mandatory;
    gap: 20px;
    /* padding-left: 70px;
    padding-right: 70px; */
    cursor: pointer;
}

.z-carousel-item{
    width: calc(100%/3 - (1 - 1/3)*25px);
    height: 330px;
    display: flex;
    margin: 0;
    transition: left .4s cubic-bezier(.47,.13,.15,.89);
    flex-shrink: 0;
    margin-right: 10px;
}

@media only screen and (max-device-width: 800px) {
    .z-carousel-item{
        width: calc(100%/3 - (1 - 1/3)*-68px);
        height: 250px;
        display: flex;
        margin: 0;
        transition: left .4s cubic-bezier(.47,.13,.15,.89);
        flex-shrink: 0;
        margin-right: 10px;
    }

    
}

/* .z-arrow-btn{
    margin-top: -420px;
} */

.z-left{
    margin-top: -80px;
    margin-left: 70px;
    width: 56px;
    height: 56px;
    position: absolute;
    font-size: 20px;
    color: #26A9E0;
    background-color: #FFF;
    border: none;
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 50px;
}

.z-right{
    margin-top: -80px;
    width: 56px;
    margin-left: 130px;
    height: 56px;
    position: absolute;
    font-size: 20px;
    color: #26A9E0;
    background-color: #FFF;
    border: none;
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 50px;
}

@media only screen and (max-width: 800px) {
    .z-left{
        width: 56px;
        height: 56px;
        margin-left: 30px;
        position: absolute;
        font-size: 20px;
        color: #26A9E0;
        background-color: #FFF;
        border: none;
        box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
        border-radius: 50px;
    }
    
    .z-right{
        width: 56px;
        margin-left: 90px;
        height: 56px;
        position: absolute;
        font-size: 20px;
        color: #26A9E0;
        background-color: #FFF;
        border: none;
        box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
        border-radius: 50px;
    }

    .z-carousel-container button{
        margin-top: 250px;
    }
}


.z-card-show{
    border-radius: 10px;
    background-color: #FFF;
    overflow: hidden;
    transition: 0.3s;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.z-carousel-title{
    margin-bottom: 30px;
    /* text-align: center; */
}
