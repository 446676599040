.z-header{
  background-color: whitesmoke;
}

.z-container{
  padding: calc(var(--sa-pixel)* 5) 0;
  margin-left: 100px;
}

.z-navbar{
  justify-content: space-between;
  width: calc(100% - 90px);
}

.z-nav {
  padding: calc(var(--sa-pixel)* 5) 0;
  align-items: center;
  /* max-width: 1160px; */
  font-weight: 700;
}

.z-nav li{
  margin-right: 4px;
}

.web-navbar-nav .active{
  /* border-bottom: 4px solid #4FE765; */
  color: #AF2245!important;
}

.navbar-nav li a:hover{
 /*  border-bottom: 4px solid #4FE765; */
  color: #AF2245!important;
}

.navbar-nav li a:active {
  /* border-bottom: 4px solid #4FE765; */
} 

.z-brand .z-logo {
  margin-right: calc(var(--sa-pixel)* 25);
  width: 150px;
  height: 50px;
}

.z-navbar{
  justify-content: space-between;
}

.z-lang-btn{
  margin-top: 20px;
  margin-bottom: 0px;
  margin-right: 80px;
}


.z-select-lang{
  display: flex;
  align-items: center;
  color: #002a56;
  font-weight: bold;
  cursor: pointer;
  top: -4px;
}

.z-lang{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
}

.z-lang-icon{
  font-size: 20px;
  margin-left: 30px;
  margin-right: 12px;
}

.z-arrow-icon{
  margin-left: 8px;
  font-size: 20px;
}

.z-notific{
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  left: 60px;
  margin-top: -34px;
  list-style: none;
  top: 30px;
}

.z-btn-list{
  list-style: none;
  margin-bottom: 10px;
}

.z-lang-nav li{
  /* width: 80px; */
  float: left;
  margin-left: 20px;
}

.z-btn-primary{
  color: white;
  background-color: #AF2245;
  /* background-image: linear-gradient(90deg, #AF2245, #882767, #602d8a); */
  margin-top: -5px;
  height: 35px;
  border: none;
  border-radius: 6px;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  width: 90px;
  /* margin-left: 100px; */
}

.z-btn-primary:hover{
  color: white;
  background-image: linear-gradient(90deg, #AF2245, #882767, #602d8a);
}

.z-btn-list span{
  margin-right: 8px;
}

.z-btn-list i{
  margin-right: 8px;
    display: flex;
    align-items: center;
    color: #002a56;
    font-weight: bold;
    cursor: pointer;
    top: -4px;
}
.navbar-nav .nav-link.show {
  color: #AF2245;
}

/* .z-lang{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
} */

/* .z-lang-icon{
    font-size: 20px;
    margin-left: 30px;
    margin-right: 12px;
} */

/* .z-arrow-icon{
    margin-left: 8px;
    font-size: 20px;
} */

/* .z-notific{
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    left: 60px;
    margin-top: -34px;
    list-style: none;
    top: 30px;
} */

/* .z-btn-list{
    list-style: none;
    margin-bottom: 10px;
} */

/* .z-lang-nav li{
    width: 80px;
    float: left;
    margin-left: 20px;
} */



/* .z-btn-list span{
    margin-right: 8px;
} */
/* 
.z-btn-list i{
    margin-right: 8px;
} */


